// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';

// Misc
import clsx from 'clsx';

// Props
interface FeaturePageHeaderProps {
  children?: React.ReactNode;
  className?: string;
  heading: string;
  icons?: React.ReactElement[];
  iconAdditional?: JSX.Element;
}

// Component
const FeaturePageHeader: React.FC<FeaturePageHeaderProps> = ({
  children = null,
  className = '',
  heading,
  icons = [],
  iconAdditional = null
}) => {
  // Vars
  const classes = clsx('lumanti-feature_page_header', className);

  // Render
  return (
    <div className={classes}>
      <div className="lumanti-feature_page_header-top-bar">
        <Heading className="lumanti-feature_page_header-header-container" size={32} type="h1">
          {heading}
        </Heading>

        <div className="lumanti-feature_page_header-extra-container">
          {iconAdditional}

          {icons?.map((icon, i) => {
            return <React.Fragment key={'key' + i.toString()}>{icon}</React.Fragment>;
          })}
        </div>
      </div>

      <div className="lumanti-feature_page_header-subheading">{children}</div>
    </div>
  );
};

export { FeaturePageHeader };
