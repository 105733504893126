// Globals
import * as React from 'react';
import './styles.scss';

// MUI
import { DataGrid } from '@mui/x-data-grid';

// Misc
import clsx from 'clsx';
import { TableProps } from './types';

const Table: React.FC<TableProps> = ({ className, columns, rows }) => {
  return (
    <div className={clsx('lumanti-table', className)} style={{ height: '100%', width: '100%' }}>
      <DataGrid
        columns={columns}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        rows={rows}
      />
    </div>
  );
};

export { Table };
