// Globals
import './styles.scss';
import React from 'react';
import { Heading } from '../../../components/Heading';
import { PieCharts } from '../../../components/PieCharts';

// Components

// Misc

interface ReportsBreakdownProps {
  charData: any;
  title: string;
  type: string;
}

// Component
const ReportsBreakdown: React.FC<ReportsBreakdownProps> = ({ charData, title, type }) => {
  return (
    <div className="lumanti-reports-breakdown">
      <Heading size={18} type="h4">
        {title}
      </Heading>
      <PieCharts chartData={charData} type={type} />
    </div>
  );
};

export { ReportsBreakdown };
