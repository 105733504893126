import * as serviceReducers from 'reducers/index';
import {
  AnyAction,
  combineReducers,
  configureStore,
  MiddlewareArray,
  StoreEnhancer
} from '@reduxjs/toolkit';
import { syncLocalStorage } from './enhancers/localStorage';

const enhancers: StoreEnhancer[] = [];

if (typeof localStorage !== 'undefined') enhancers.push(syncLocalStorage);

const combinedReducers = combineReducers(serviceReducers);

const rootReducer = (state: any, action: AnyAction) => {
  // if (action.type === true) {
  //   return combinedReducers(undefined, action);
  // }
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'meta.arg', // toolkit default
          'meta.baseQueryMeta' // toolkit default
        ]
      }
    }),
  enhancers
});

export type LmtState = ReturnType<typeof rootReducer>;
export type LmtDispatch = typeof store.dispatch;
export type LmtStore = typeof store;

export default store;
