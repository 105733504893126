// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { CardIncome } from 'components/CardIncome';
import { convertCentsToDollar } from 'utils/orders';
import { FeaturePageHeader } from 'components/PageHeader';
import { Layout } from 'components/Layout';
import { LoadingV2 } from 'components/LoadingV2';
import { ModalAddOrderV2 } from 'Modals/ModalAddOrderV2';
import { ModalConfirm } from 'Modals/ModalConfirm';
import { ModalUpdateOrder } from 'Modals/ModalUpdateOrder';
import { TabBar } from 'components/Tabs';

// Services
import { doDeleteOrder, doGetOrders } from 'services/orders/async';
import { getApiIsLoading } from 'services/api/selectors';
import {
  getFilteredOrdersListActive,
  getOrdersListCompleted,
  getTotalIncomeThisWeek,
  getTotalIncomeYearToDate
} from 'services/orders/selectors';
import {
  orderDetailsInitial,
  orderInfoInitial,
  orderMiscInitial,
  paymentInfoInitial
} from 'Modals/ModalUpdateOrder/types';

// Misc
import { LmtDispatch } from 'store';
import { OrdersDetail } from 'services/orders/types';
import { useDispatch, useSelector } from 'react-redux';
import { OrdersFilters } from './Filters';
import { OrdersList } from './List';
import { sortBy } from 'lodash';

export interface OrderListBody {
  address: string;
  delivery: string;
  deposit: string;
  discount: string;
  discountAmount: string;
  id: string;
  name: string;
  orderDetails: OrdersDetail[];
  orderNumber: string;
  paymentMethod: string;
  paymentStatus: string;
  phoneNumber: string;
  serviceDate: string;
  subTotal: string;
  taxAmount: string;
  totalAmount: string;
}

const tabBarItems = ['Upcoming Orders', 'Completed Orders'];

// Component
const Orders: React.FC = () => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  // Hooks - state
  const [isAddOrderModalVisible, setIsAddOrderModalVisible] = React.useState(false);
  const [isUpdateOrderModalVisible, setIsUpdateOrderModalVisible] = React.useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = React.useState(false);
  const [orderInfo, setOrderInfo] = React.useState(orderInfoInitial);
  const [orderDetails, setOrderDetails] = React.useState(orderDetailsInitial);
  const [orderMisc, setOrderMisc] = React.useState(orderMiscInitial);
  const [paymentInfo, setPaymentInfo] = React.useState(paymentInfoInitial);
  const [orderId, setOrderId] = React.useState('');
  const [tabIndex, setTabIndex] = React.useState(0);

  // Hooks - selectors
  const getApiLoading = useSelector(getApiIsLoading);
  const incomeThisWeek = useSelector(getTotalIncomeThisWeek);
  const incomeYearToDate = useSelector(getTotalIncomeYearToDate);
  const orderListActive: OrderListBody[] = useSelector(getFilteredOrdersListActive);
  const orderListCompleted: OrderListBody[] = useSelector(getOrdersListCompleted);

  React.useEffect(() => {
    void dispatch<any>(doGetOrders());
  }, []);

  // Handler
  const handleDeleteOrder = (orderId: string) => {
    setOrderId(orderId);
    setIsConfirmModalVisible(true);
  };

  const handleUpdateModal = (order: any) => {
    setOrderId(order.id);
    setOrderInfo({
      serviceDate: order.serviceDate,
      serviceTime: order.serviceTime,
      name: order.name,
      address: order.address,
      phoneNumber: order.phoneNumber
    });
    setOrderDetails(order.orderDetails);
    setOrderMisc({ discount: order.discount, tax: order.tax, delivery: order.delivery });
    setPaymentInfo({
      deposit: order.deposit ?? '',
      paymentMethod: order.paymentMethod,
      paymentStatus: order.paymentStatus
    });
    setIsUpdateOrderModalVisible(true);
  };

  // Render
  return (
    <Layout className="lumanti-orders">
      {getApiLoading && (
        <div className="lumanti-orders-loading">
          <LoadingV2 />
        </div>
      )}

      {!getApiLoading && (
        <div className="lumanti-orders-body">
          <FeaturePageHeader heading="Orders" />

          <div className="lumanti-orders-data-cards">
            <CardIncome label="Weekly Income" value={convertCentsToDollar(incomeThisWeek)} />
            <CardIncome
              label="Year to date Income"
              value={convertCentsToDollar(incomeYearToDate)}
            />
          </div>

          <div className="lumanti-orders-nav-bar">
            <Button icon="plus" onClick={() => setIsAddOrderModalVisible(true)}>
              Add Order
            </Button>

            <TabBar setTabIndex={setTabIndex} tabIndex={tabIndex} tabItems={tabBarItems} />
          </div>

          {tabIndex === 0 && (
            <>
              <OrdersFilters />
              <OrdersList
                handleDeleteOrder={handleDeleteOrder}
                handleUpdateModal={handleUpdateModal}
                orderList={sortBy(orderListActive, ['serviceDate'])}
              />
            </>
          )}

          {tabIndex === 1 && (
            <div className="lumanti-orders-completed">
              <OrdersList
                handleDeleteOrder={handleDeleteOrder}
                handleUpdateModal={handleUpdateModal}
                orderList={orderListCompleted}
              />
            </div>
          )}
        </div>
      )}

      <ModalAddOrderV2
        hide={() => setIsAddOrderModalVisible(false)}
        isVisible={isAddOrderModalVisible}
      />

      <ModalUpdateOrder
        hide={() => setIsUpdateOrderModalVisible(false)}
        isVisible={isUpdateOrderModalVisible}
        orderId={orderId}
        updateOrderDetails={orderDetails}
        updateOrderInfo={orderInfo}
        updateOrderMisc={orderMisc}
        updatePaymentInfo={paymentInfo}
      />

      <ModalConfirm
        heading="Confirm"
        isVisible={isConfirmModalVisible}
        onConfirm={() => dispatch<any>(doDeleteOrder(orderId))}
        setIsVisible={() => setIsConfirmModalVisible(false)}
        subheading="Are you sure you want to delete the order?"
      />
    </Layout>
  );
};

export { Orders };
