// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

// Misc
import { capitalize } from 'lodash';
import { formatCurrency } from 'utils/currencyFormat';
import { PieChart, Pie, Cell, Sector, Legend } from 'recharts';

interface PieChartProps {
  chartData: any;
  type: string;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#7a4100', '#9909e0', '#4c5570'];
const COLORS_CODE: Record<string, string> = {
  '#0088FE': 'BLUE',
  '#00C49F': 'GREEN',
  '#FFBB28': 'YELLOW',
  '#FF8042': 'ORANGE',
  '#7a4100': 'BROWN',
  '#9909e0': 'PURPLE',
  '#4c5570': 'GREY'
};
const RADIAN = Math.PI / 180;

const renderActiveShape = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } =
    props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text dy={8} fill={fill} textAnchor="middle" x={cx} y={cy}>
        {capitalize(payload.name as string)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} fill="none" stroke={fill} />
      <circle cx={ex} cy={ey} fill={fill} r={2} stroke="none" />
      <text fill="#d6e6ff" textAnchor={textAnchor} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey}>
        ${value}
      </text>
      <text dy={18} fill="#999" textAnchor={textAnchor} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} />
    </g>
  );
};

const renderLegend = (props: any) => {
  const { payload } = props;
  return (
    <div className="lumanti-pie_charts-legends">
      <div className="lumanti-pie_charts-legends-row">
        <Heading size={16} type="h3">
          CATEGORY
        </Heading>
        <Paragraph>VALUE</Paragraph>
      </div>
      {payload.map((entry: any, index: number) => {
        const { payload, color } = entry;
        return (
          <div className="lumanti-pie_charts-legends-row" key={String(index)}>
            <Heading color={COLORS_CODE[color]} size={16} type="h3">
              {capitalize(payload.name)}
            </Heading>
            <Paragraph>${payload.value}</Paragraph>
          </div>
        );
      })}
    </div>
  );
};

// Component
const PieCharts: React.FC<PieChartProps> = ({ chartData, type }) => {
  // Hooks - state
  const [activeIndex, setActiveIndex] = React.useState(0);

  const onPieEnter = React.useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const formatChartData = (data: any) => {
    return data.map((item: any) => ({
      name: item.name,
      value:
        type === 'income' ? parseFloat(item.value) / 100 : parseFloat(formatCurrency(item.value))
    }));
  };

  const data = formatChartData(chartData);

  const isMobile = window.innerWidth < 720;

  const pieChartHeight = isMobile ? 400 : 400;
  const pieChartWidth = isMobile ? 325 : 450;
  const pieChartInnerRadius = isMobile ? 40 : 60;
  const pieChartOuterRadius = isMobile ? 90 : 100;

  return (
    <PieChart className="lumanti-pie_charts" height={pieChartHeight} width={pieChartWidth}>
      <Legend content={renderLegend} />
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        cx="50%"
        cy="50%"
        data={data}
        dataKey="value"
        fill="#8884d8"
        innerRadius={pieChartInnerRadius}
        onMouseEnter={onPieEnter}
        outerRadius={pieChartOuterRadius}
      >
        {data.map((entry: any, index: number) => (
          <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
        ))}
      </Pie>
    </PieChart>
  );
};

export { PieCharts };
