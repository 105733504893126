// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { FeaturePageHeader } from 'components/PageHeader';
import { Layout } from 'components/Layout';
import { ModalPLBreakdown } from 'Modals/ModalPLBreakdown';
import { ReportsBreakdown } from './Breakdown';
import { ReportsStats } from './Stats';
import { MoreMenu } from 'components/MoreMenu';

// Services
import { doGetExpenses } from 'services/expense/async';
import { doGetOrders } from 'services/orders/async';
import {
  getReportPaidTransactionsTaxAndDelivery,
  getReportsExpenses,
  getReportsExpensesGroupByCardOwnerSumGroup,
  getReportsExpensesTotal,
  getReportTransactions,
  getReportTransactionSumGroupTotal
} from 'services/reports/selectors';
import { setDateRange } from 'services/reports/reducers';

// Misc
import dayjs from 'dayjs';
import { convertCentsToDollar, formatCurrency } from 'utils/orders';
import { LmtDispatch } from 'store';
import { ReportsDates } from './Dates';
import { useDispatch, useSelector } from 'react-redux';
import { Heading } from '../../components/Heading';

// Component
const Reports: React.FC = () => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  // Hooks - state
  const [dateStart, setDateStart] = React.useState(dayjs(new Date()));
  const [dateEnd, setDateEnd] = React.useState(dayjs(new Date()));
  const [isVisible, setIsVisible] = React.useState(false);

  // Hooks - Selectors
  const transactionsRevenue = useSelector(getReportTransactions);
  const transactionsRevenueTotal = useSelector(getReportTransactionSumGroupTotal);
  const transactionsExpenses = useSelector(getReportsExpenses);
  const transactionsExpensesReimmbursement = useSelector(
    getReportsExpensesGroupByCardOwnerSumGroup
  );
  const transactionsExpensesTotal = useSelector(getReportsExpensesTotal);
  const transactionsRevenueTaxAndDelivery = useSelector(getReportPaidTransactionsTaxAndDelivery);

  // Hooks - useEffect
  React.useEffect(() => {
    void dispatch<any>(doGetOrders());
    void dispatch<any>(doGetExpenses());
  }, []);

  // Vars
  const taxesCollectedTotal = convertCentsToDollar(
    transactionsRevenueTaxAndDelivery.taxAmount.toString()
  );

  const deliveryFeesCollectedTotal = transactionsRevenueTaxAndDelivery.delivery;
  const reimbursementTotal = formatCurrency(transactionsExpensesReimmbursement?.personal);

  const taxesAndFeesCollected = Number(taxesCollectedTotal) + Number(deliveryFeesCollectedTotal);

  const grossProfitOrLoss = formatCurrency(
    (
      Number(convertCentsToDollar(transactionsRevenueTotal.toString())) -
      Number(formatCurrency(transactionsExpensesTotal.toString()))
    ).toString()
  );

  const netProfitOrLoss = formatCurrency(
    (
      Number(convertCentsToDollar(transactionsRevenueTotal.toString())) -
      (Number(formatCurrency(transactionsExpensesTotal.toString())) + Number(taxesCollectedTotal))
    ).toString()
  );

  const options = [{ label: 'View Full Breakdown', cta: () => setIsVisible(true) }];

  // Handler
  const onSetDateRangeFilter = () => {
    void dispatch<any>(
      setDateRange({ dateStart: dateStart.format('LL'), dateEnd: dateEnd.format('LL') })
    );
  };

  return (
    <Layout className="lumanti-reports">
      <FeaturePageHeader heading="Reports" />

      <div className="lumanti-reports-search">
        <ReportsDates
          dateEnd={dateEnd}
          dateStart={dateStart}
          setDateEnd={setDateEnd}
          setDateStart={setDateStart}
        />

        <Button onClick={onSetDateRangeFilter}>Run Report</Button>
      </div>

      {transactionsRevenue.length > 0 && transactionsExpenses.length > 0 && (
        <div className="lumanti-reports-analytic">
          <Card title="Profit & Loss Stats">
            <div className="lumanti-reports-pnl">
              <ReportsStats
                icon="ico-glass-chart.svg"
                title="Sales"
                value={convertCentsToDollar(transactionsRevenueTotal.toString())}
              />
              <ReportsStats
                icon="ico-glass-discount.svg"
                title="Expenses"
                value={formatCurrency(transactionsExpensesTotal.toString())}
              />
              <ReportsStats icon="ico-glass-tick.svg" title="Profit" value={grossProfitOrLoss} />
            </div>
          </Card>

          <Card title="Taxes, Fees & Reimbursement">
            <div className="lumanti-reports-misc">
              <ReportsStats icon="tax.png" title="Taxes Collected" value={taxesCollectedTotal} />
              <ReportsStats
                icon="delivery.png"
                title="Deliver Fees Collected"
                value={deliveryFeesCollectedTotal}
              />
              <ReportsStats
                icon="reimbursement.png"
                title="Reimbursement"
                value={reimbursementTotal}
              />
            </div>
          </Card>

          <Card
            className="lumanti-reports-income-expenses-breakdown"
            moreOption={<MoreMenu options={options} />}
            title="Income & Expenses Breakdown"
          >
            <div className="lumanti-reports-income-expenses-breakdwon-container">
              <ReportsBreakdown
                charData={transactionsRevenue}
                title="Revenue by Source"
                type="income"
              />
              <ReportsBreakdown charData={transactionsExpenses} title="Expenses" type="expense" />
            </div>
          </Card>
        </div>
      )}

      {transactionsRevenue.length === 0 && transactionsExpenses.length === 0 && (
        <div className="lumanti-reports-no-report">
          <img alt="no report" src={require('assets/media/illustration/no-report.png')} />
          <Heading size={22} type="h3">
            Select Date Range to see Report
          </Heading>
        </div>
      )}

      <ModalPLBreakdown
        hide={() => setIsVisible(false)}
        isVisible={isVisible}
        profitOrLoss={netProfitOrLoss}
        taxesAndFeesTotal={taxesAndFeesCollected}
        transactionsExpenses={transactionsExpenses}
        transactionsExpensesReimmbursement={transactionsExpensesReimmbursement}
        transactionsExpensesTotal={transactionsExpensesTotal}
        transactionsRevenue={transactionsRevenue}
        transactionsRevenueTaxAndDelivery={transactionsRevenueTaxAndDelivery}
        transactionsRevenueTotal={transactionsRevenueTotal}
      />
    </Layout>
  );
};

export { Reports };
