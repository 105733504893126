// Global
import './styles.scss';
import React from 'react';

// MUI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { InputAdornment, OutlinedInput } from '@mui/material';

// Misc
import { textFieldClasses } from 'utils/MuiClasses';
import {
  PaymentInfo,
  PaymentMethod,
  paymentMethodOptions,
  PaymentStatus,
  paymentStatusOptions
} from '../types';

interface ModalAddOrderV2PaymentInfoProps {
  paymentInfo: PaymentInfo;
  setPaymentInfo: (paymentInfo: PaymentInfo) => void;
}

// Component
const ModalAddOrderV2PaymentInfo: React.FC<ModalAddOrderV2PaymentInfoProps> = ({
  paymentInfo,
  setPaymentInfo
}) => {
  // Hooks
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(PaymentMethod.NA);
  const [paymentStatus, setPaymentStatus] = React.useState(PaymentStatus.UNPAID);

  const handlePaymentMethodChange = (event: SelectChangeEvent) => {
    const value = event.target.value as PaymentMethod;
    setPaymentMethod(value);
    setPaymentInfo({ ...paymentInfo, paymentMethod: value });
  };

  const handlePaymentStatusChange = (event: SelectChangeEvent) => {
    const value = event.target.value as PaymentStatus;
    setPaymentStatus(value);
    setPaymentInfo({ ...paymentInfo, paymentStatus: value });
  };

  const handleInputOnChange = (value: string, field: keyof PaymentInfo) => {
    setPaymentInfo({ ...paymentInfo, [field]: value });
  };

  return (
    <Box sx={textFieldClasses}>
      <div className="lumanti-modal_add_order_v2_payment_info">
        <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Deposit</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            label="Deposit"
            name="deposit"
            onChange={(e) => handleInputOnChange((e.target as HTMLInputElement).value, 'deposit')}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={paymentInfo.deposit}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="payment-method-select-label">Payment Status</InputLabel>
          <Select
            id="payment-status-select"
            label="Payment Status"
            labelId="paymentStatus"
            onChange={handlePaymentStatusChange}
            value={paymentStatus as any}
          >
            {paymentStatusOptions.map((option) => {
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="payment-method-select-label">Payment Method</InputLabel>
          <Select
            id="payment-select"
            label="Payment Method"
            labelId="paymentMethod"
            onChange={handlePaymentMethodChange}
            value={paymentMethod as any}
          >
            {paymentMethodOptions.map((option) => {
              return (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
};

export { ModalAddOrderV2PaymentInfo };
