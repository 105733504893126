import { createSelector } from 'reselect';
import { LmtState } from 'store';

const getAuth = createSelector(
  (state: LmtState) => state,
  (state) => state.auth
);

export const getAccessToken = createSelector(getAuth, (auth) => auth.accessToken);

export const getUserDetails = createSelector(getAuth, (auth) => auth.userDetails);

export const getAuthDisplayName = createSelector(
  getUserDetails,
  (userDetails) => userDetails.displayName
);

export const getAuthDisplayNameFirstName = createSelector(
  getAuthDisplayName,
  (displayName) => displayName.split(' ')[0] ?? ''
);

export const getAuthError = createSelector(getAuth, (auth) => auth.authError ?? '');
