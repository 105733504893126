import { addDoc, collection, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase';
import { LmtThunk } from 'store/types';
import { setLoading } from '../api/reducers';
import { setExpenses } from './reducers';

const expenseCollectionRef = collection(db, 'expense');

export function doGetExpenses(): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const resp = await getDocs(expenseCollectionRef);
      const filteredData = resp.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      dispatch(setExpenses(filteredData));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function doAddExpense(data: any): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await addDoc(expenseCollectionRef, data);
      await dispatch(doGetExpenses());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}
