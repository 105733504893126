// Globals
import './styles.scss';
import React from 'react';

// Components
import { Paragraph } from 'components/Paragraph';
import { Heading } from 'components/Heading';

// Misc

interface ReportsStatsProps {
  title: string;
  icon: string;
  value: string | number;
}

// Component
const ReportsStats: React.FC<ReportsStatsProps> = ({ title, icon, value }) => {
  return (
    <div className="lumanti-reports_stats">
      <img
        alt="stats"
        className="lumanti-reports_stats-img"
        src={require(`assets/media/illustration/${icon}`)}
      />
      <div className="lumanti-reports_stats_title_value">
        <Paragraph>{title}</Paragraph>
        <Heading size={22} type="h3">
          ${value}
        </Heading>
      </div>
    </div>
  );
};

export { ReportsStats };
