// Globals
import './styles.scss';
import React from 'react';

// Components

// Misc

// MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabBarProps {
  tabItems: String[];
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({ tabItems, tabIndex, setTabIndex }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Tabs className="lumanti-tab_bar" onChange={handleChange} value={tabIndex}>
      {tabItems.map((item) => {
        return <Tab key={item.toString()} label={item} />;
      })}
    </Tabs>
  );
};

export { TabBar };
