import { format } from 'date-fns';

export function formatDate(date?: string) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'MMM d, yyyy');
  } catch {
    return '';
  }
}
