// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBpVIwLZy56OjEYEm7El5qA3wkflXSXWOY',
  authDomain: 'lumanti-48d67.firebaseapp.com',
  projectId: 'lumanti-48d67',
  storageBucket: 'lumanti-48d67.appspot.com',
  messagingSenderId: '540749246928',
  appId: '1:540749246928:web:90fa1182d925cc37e2c9d5',
  measurementId: 'G-183KFNC38V'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
