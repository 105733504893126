import { md5 as hash } from 'pure-md5';
import persistState from 'redux-localstorage';
import { LmtState } from '../index';
import { WritableDraft } from 'immer/dist/internal';
import { StoreEnhancer } from '@reduxjs/toolkit';

// State keys to be stored
const paths = ['auth'];

// Name the key used in browser local storage
const key = `${hash(`app.lumanti.prod`) as string}`;

function slicer(paths: Array<keyof LmtState>) {
  return (state: LmtState) => {
    const subset: WritableDraft<Partial<LmtState>> = {};
    paths.forEach((path) => {
      // @ts-ignore
      subset[path] = state[path];
    });
    return subset;
  };
}

// @ts-ignore
export const syncLocalStorage: StoreEnhancer = persistState(paths, { key, slicer });
