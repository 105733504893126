// Globals
import React from 'react';

// Components
import { Home } from 'pages/Home';
import { Orders } from './pages/Orders';
import { SignIn } from 'pages/SignIn';
import { Expenses } from './pages/Expenses';
import { Reports } from './pages/Reports';

export interface LumantiRoute {
  auth?: boolean;
  component: React.ElementType;
  exact?: boolean;
  name?: string;
  path?: string;
}

const routes: LumantiRoute[] = [
  { auth: true, component: Home, path: '/' },
  { auth: true, component: Orders, path: '/orders' },
  { auth: true, component: Expenses, path: '/expenses' },
  { auth: true, component: Reports, path: '/reports' },
  { component: SignIn, path: '/sign-in' }
  // {
  //   exact: true,
  //   path: '/not-found',
  //   component: Error404
  // },
  // {
  //   path: '*',
  //   component: Error404
  // }
];

export { routes };
