import { createSelector } from 'reselect';
import { LmtState } from 'store';
import { groupBy, sortBy } from 'lodash';
import { TimeLineData } from '../../components/TimeLine/types';
import { isToday } from '../../utils/dateComparison';
import { format, isThisMonth, isThisWeek, isThisYear } from 'date-fns';
import { convertCentsToDollar } from '../../utils/orders';
import { calculateSumsGroup } from '../../utils/dataTransform';

export const getOrders = createSelector(
  (state: LmtState) => state,
  (state) => state.orders
);

// Filter
export const getOrdersFilter = createSelector(getOrders, (orders) => orders.filter);

export const getOrdersList = createSelector(getOrders, (orders) =>
  sortBy(orders.list, ['serviceDate']).reverse()
);

export const getOrdersListActive = createSelector(getOrdersList, (orderList) =>
  orderList.filter((list) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();

    const dateNow = new Date(currentYear, currentMonth, currentDate);

    const serviceYear = new Date(list.serviceDate).getFullYear();
    const serviceMonth = new Date(list.serviceDate).getMonth();
    const serviceDate = new Date(list.serviceDate).getDate();

    const serviceDate1 = new Date(serviceYear, serviceMonth, serviceDate);

    return serviceDate1 >= dateNow;
  })
);

export const getOrdersListCompleted = createSelector(getOrdersList, (orderList) =>
  orderList.filter((list) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();

    const dateNow = new Date(currentYear, currentMonth, currentDate);

    const serviceYear = new Date(list.serviceDate).getFullYear();
    const serviceMonth = new Date(list.serviceDate).getMonth();
    const serviceDate = new Date(list.serviceDate).getDate();

    const serviceDate1 = new Date(serviceYear, serviceMonth, serviceDate);

    return serviceDate1 < dateNow;
  })
);

export const getOrdersListTimeLine = createSelector(getOrdersList, (ordersList) =>
  ordersList
    .reduce((arr: TimeLineData[], list) => {
      const obj = {
        name: list.name,
        date: list.serviceDate,
        time: '',
        phoneNumber: list.phoneNumber,
        totalAmount: list.totalAmount
      };

      arr.push(obj);

      return arr;
    }, [])
    .reverse()
    .slice(-4)
);

export const getFilteredOrdersListActive = createSelector(
  getOrdersListActive,
  getOrdersFilter,
  (orders, filter) => {
    switch (filter) {
      case 'today':
        return orders.filter((order: any) => {
          return isToday(new Date(order.serviceDate));
        });
      case 'week':
        return orders.filter((order: any) => {
          const year = new Date(order.serviceDate).getFullYear();
          const month = new Date(order.serviceDate).getMonth();
          const date = new Date(order.serviceDate).getDate();
          return isThisWeek(new Date(year, month, date), { weekStartsOn: 1 });
        });
      case 'month':
        return orders.filter((order: any) => {
          const year = new Date(order.serviceDate).getFullYear();
          const month = new Date(order.serviceDate).getMonth();
          const date = new Date(order.serviceDate).getDate();
          return isThisMonth(new Date(year, month, date));
        });
      default:
        return orders;
    }
  }
);

export const getFilteredOrdersList = createSelector(
  getOrdersList,
  getOrdersFilter,
  (orders, filter) => {
    switch (filter) {
      case 'today':
        return orders.filter((order: any) => {
          return isToday(new Date(order.serviceDate));
        });
      case 'week':
        return orders.filter((order: any) => {
          const year = new Date(order.serviceDate).getFullYear();
          const month = new Date(order.serviceDate).getMonth();
          const date = new Date(order.serviceDate).getDate();
          return isThisWeek(new Date(year, month, date), { weekStartsOn: 1 });
        });
      case 'month':
        return orders.filter((order: any) => {
          const year = new Date(order.serviceDate).getFullYear();
          const month = new Date(order.serviceDate).getMonth();
          const date = new Date(order.serviceDate).getDate();
          return isThisMonth(new Date(year, month, date));
        });
      default:
        return orders;
    }
  }
);

export const getOrdersListPaidGroupByPaymentMethod = createSelector(
  getFilteredOrdersList,
  (ordersList) => {
    const list = ordersList.filter((list) => list?.paymentStatus === 'paid');
    return groupBy(list, (n) => n.paymentMethod);
  }
);

export const getOrdersListPaidTotal = createSelector(
  getOrdersListPaidGroupByPaymentMethod,
  (paidList) => calculateSumsGroup(paidList, 'totalAmount')
);

// export const getFilteredOrdersList = createSelector(
//   getOrdersList,
//   getOrdersFilter,
//   (orders, filter) => {
//     return orders.filter((order: any) => {
//       return isToday(new Date(order.serviceDate));
//     });
//   }
// );

export const getOrdersListWeekly = createSelector(getOrdersList, (orderList) => {
  return orderList.filter((order: any) => {
    const year = new Date(order.serviceDate).getFullYear();
    const month = new Date(order.serviceDate).getMonth();
    const date = new Date(order.serviceDate).getDate();
    return isThisWeek(new Date(year, month, date), { weekStartsOn: 1 });
  });
});

export const getOrdersListYearToDate = createSelector(getOrdersList, (orderList) => {
  return orderList.filter((order: any) => {
    const year = new Date(order.serviceDate).getFullYear();
    const month = new Date(order.serviceDate).getMonth();
    const date = new Date(order.serviceDate).getDate();
    return isThisYear(new Date(year, month, date));
  });
});

export const getOrdersListWeeklySales = createSelector(getOrdersListWeekly, (salesWeekly) => {
  return salesWeekly
    .reduce((data: Record<string, string>[], sales) => {
      const date = format(new Date(sales.serviceDate), 'E');
      const obj = { name: date, amount: convertCentsToDollar(sales.totalAmount) };
      const index = data.findIndex((ele) => ele.name === date);
      if (index > -1) {
        const amt = data[index].amount;
        data[index].amount = (
          Number(amt) + Number(convertCentsToDollar(sales.totalAmount))
        ).toString();
      } else {
        data.push(obj);
      }

      return data;
    }, [])
    .reverse();
});

export const getTotalIncomeThisWeek = createSelector(getOrdersListWeekly, (weeklyOrders) => {
  return weeklyOrders.reduce((arr: string, list) => {
    arr = (Number(arr) + Number(list.totalAmount)).toString();
    return arr;
  }, '0');
});

export const getTotalIncomeYearToDate = createSelector(
  getOrdersListYearToDate,
  (ordersYearToDate) => {
    return ordersYearToDate.reduce((arr: string, list) => {
      arr = (Number(arr) + Number(list.totalAmount)).toString();
      return arr;
    }, '0');
  }
);
