import { createSelector } from 'reselect';
import { LmtState } from 'store';
import { isThisWeek, isThisYear } from 'date-fns';

export const getExpenses = createSelector(
  (state: LmtState) => state,
  (state) => state.expenses
);

export const getExpensesList = createSelector(getExpenses, (expenses) => expenses.list);

export const getExpensesListSorted = createSelector(getExpensesList, (sortedList) =>
  sortedList.sort((a, b) => new Date(b.receiptDate).getTime() - new Date(a.receiptDate).getTime())
);

export const getExpensesListWeekly = createSelector(getExpensesList, (expenseList) => {
  const list = expenseList.filter((expense: any) => {
    const year = new Date(expense.receiptDate).getFullYear();
    const month = new Date(expense.receiptDate).getMonth();
    const day = new Date(expense.receiptDate).getDate();
    return isThisWeek(new Date(year, month, day), { weekStartsOn: 1 });
  });
  return list || [];
});

export const getExpensesListYearToDate = createSelector(getExpensesList, (expenseList) => {
  const list = expenseList.filter((expense: any) => {
    const year = new Date(expense.receiptDate).getFullYear();
    const month = new Date(expense.receiptDate).getMonth();
    const day = new Date(expense.receiptDate).getDate();
    return isThisYear(new Date(year, month, day));
  });
  return list || [];
});

export const getTotalExpensesThisWeek = createSelector(getExpensesListWeekly, (expenseWeekly) => {
  return expenseWeekly.reduce((arr: string, list) => {
    arr = (Number(arr) + Number(list.amount)).toString();
    return arr;
  }, '0');
});

export const getTotalExpensesYearToDate = createSelector(
  getExpensesListYearToDate,
  (expenseYearToDate) => {
    return expenseYearToDate.reduce((arr: string, list) => {
      arr = (Number(arr) + Number(list.amount)).toString();
      return arr;
    }, '0');
  }
);
