import { AllExpensesAction, ExpenseList, ExpensesActionTypes, ExpensesState } from './types';
import { combineReducers } from '@reduxjs/toolkit';

export const setExpenses = (data: any) => ({
  type: ExpensesActionTypes.SET_EXPENSES,
  data
});

export const setFilter = (data: string) => ({
  type: ExpensesActionTypes.SET_FILTER,
  data
});

// Sub-reducers
export function list(list: ExpenseList[] = [], action: AllExpensesAction) {
  switch (action.type) {
    case ExpensesActionTypes.SET_EXPENSES: {
      return action.data;
    }
    default:
      return list;
  }
}

export function filter(filter: string = 'all', action: AllExpensesAction) {
  switch (action.type) {
    case ExpensesActionTypes.SET_FILTER:
      return action.data;
    default:
      return filter;
  }
}

// Root Reducer
export const expenses = combineReducers<ExpensesState>({
  list,
  filter
});
