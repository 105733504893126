// Globals
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBodyDialog } from 'components/Modal/BodyDialog';

interface ModalConfirmProps {
  heading: string;
  isVisible: boolean;
  onConfirm: () => void;
  setIsVisible: () => void;
  subheading: string;
}
// Component
const ModalConfirm: React.FC<ModalConfirmProps> = ({
  heading,
  isVisible,
  onConfirm,
  setIsVisible,
  subheading
}) => {
  const handleConfirmButtonClick = () => {
    onConfirm();
    setIsVisible();
  };
  return (
    <Modal className="lumanti-modal_confirm" hide={setIsVisible} isVisible={isVisible}>
      <ModalBodyDialog
        buttonCancel={
          <Button display="inline" intent="secondary" onClick={() => setIsVisible()} type="button">
            Cancel
          </Button>
        }
        buttonSave={
          <Button
            display="inline"
            intent="primary"
            onClick={handleConfirmButtonClick}
            type="button"
          >
            Confirm
          </Button>
        }
        heading={heading}
        subheading={subheading}
      ></ModalBodyDialog>
    </Modal>
  );
};

export { ModalConfirm };
