// Global
import './styles.scss';
import React from 'react';

// Mui
import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import Box from '@mui/material/Box';

// Types
import { OrderMisc, textFieldClasses } from '../types';

interface ModalUpdateOrderMiscProps {
  orderMisc: OrderMisc;
  setOrderMisc: (orderMisc: OrderMisc) => void;
}

// Component
const ModalUpdateOrderMisc: React.FC<ModalUpdateOrderMiscProps> = ({ orderMisc, setOrderMisc }) => {
  // Handler
  const handleOnChange = (value: string, field: keyof OrderMisc) => {
    setOrderMisc({ ...orderMisc, [field]: value });
  };

  return (
    <Box sx={textFieldClasses}>
      <div className="lumanti-modal_update_order_misc">
        <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Discount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            label="Discount"
            name="discount"
            onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'discount')}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            value={orderMisc.discount}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Delivery</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            label="Delivery"
            name="delivery"
            onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'delivery')}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={orderMisc.delivery}
          />
        </FormControl>
        <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Tax</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            label="Tax"
            name="tax"
            onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'tax')}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            value={orderMisc.tax}
          />
        </FormControl>
      </div>
    </Box>
  );
};

export { ModalUpdateOrderMisc };
