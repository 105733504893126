// Action types
export enum ReportsActionTypes {
  SET_DATE_RANGE_FILTER = 'lumanti.orders.SET_DATE_RANGE_FILTER'
}

// Generic Orders actions
export interface ReportsAction<P> {
  type: ReportsActionTypes;
  data: P;
}

export interface ReportsDateRangeFilter extends ReportsAction<any> {
  type: ReportsActionTypes.SET_DATE_RANGE_FILTER;
}

export type AllReportAction = ReportsDateRangeFilter;

export interface ReportsState {
  dateRangeFilter: DateRangeFilter;
}

export interface DateRangeFilter {
  dateStart: string;
  dateEnd: string;
}

export const initialDateRangeFilter = {
  dateStart: new Date().toString(),
  dateEnd: new Date().toString()
};
