// Action types
export enum AuthActionTypes {
  CLEAR_USER_TOKEN = 'lumanti.auth.CLEAR_USER_TOKEN',
  SET_USER_TOKEN = 'lumanti.auth.SET_USER_TOKEN',
  SET_ERROR = 'lumanti.auth.SET_ERROR',
  CLEAR_ERROR = 'lumanti.auth.CLEAR_ERROR'
}

// Generic Auth actions
export interface AuthAction<P> {
  type: AuthActionTypes;
  data: P;
}

export interface AuthClearTokenAction extends AuthAction<null> {
  type: AuthActionTypes.CLEAR_USER_TOKEN;
}

export interface AuthSetTokensAction extends AuthAction<any> {
  type: AuthActionTypes.SET_USER_TOKEN;
}

export interface AuthSetErrorsAction extends AuthAction<any> {
  type: AuthActionTypes.SET_ERROR;
}

export interface AuthClearErrorAction extends AuthAction<null> {
  type: AuthActionTypes.CLEAR_ERROR;
}

export type AllAuthActions =
  | AuthClearTokenAction
  | AuthSetTokensAction
  | AuthSetErrorsAction
  | AuthClearErrorAction;

export interface UserDetails {
  displayName: string;
  email: string;
  photoUrl: string;
  uid: string;
}

export interface AuthState {
  accessToken: string;
  userDetails: UserDetails;
  authError: string;
}

export interface LoginAuthParams {
  email: string;
  password: string;
}
