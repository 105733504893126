// Action types
export enum ApiActionTypes {
  SET_LOADING = 'lumanti.api.SET_LOADING'
}

// Generic api actions
export interface ApiAction<P> {
  type: ApiActionTypes;
  data: P;
}

export interface ApiSetLoading extends ApiAction<boolean> {
  type: ApiActionTypes.SET_LOADING;
}

export type AllApiActions = ApiSetLoading;

export interface ApiState {
  isLoading: boolean;
}
