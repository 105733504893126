// Globals
import React from 'react';

// Components
import { SideNavBarDrawer } from './Drawer';

// Mui
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';

const drawerWidth = 240;

interface SideNavBarProps {
  mobileOpen: boolean;
  setMobileOpen: (mobileOpen: boolean) => void;
}

// Component
const SideNavBar: React.FC<SideNavBarProps> = ({ mobileOpen, setMobileOpen }) => {
  const container = () => window.document.body;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      aria-label="mailbox folders"
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            backgroundColor: '#121e2d',
            color: '#d6e6ff'
          }
        }}
        container={container}
        onClose={handleDrawerToggle}
        open={mobileOpen}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
        variant="temporary"
      >
        <SideNavBarDrawer />
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: '#121e2d',
            color: '#d6e6ff'
          }
        }}
        open
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
        variant="permanent"
      >
        <SideNavBarDrawer />
      </Drawer>
    </Box>
  );
};

export { SideNavBar };
