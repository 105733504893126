import './styles.scss';
import React from 'react';

// Components
import { CardExpense } from 'components/CardExpense';
import { CardIncome } from 'components/CardIncome';

// Services
import { doGetExpenses } from 'services/expense/async';
import { getTotalExpensesThisWeek, getTotalExpensesYearToDate } from 'services/expense/selectors';
import { getTotalIncomeThisWeek, getTotalIncomeYearToDate } from 'services/orders/selectors';

// Misc
import { convertCentsToDollar } from 'utils/orders';
import { formatCurrency } from 'utils/currencyFormat';
import { LmtDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';

const HomeIncomeExpense: React.FC = () => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();
  // Hooks - Selectors
  const expensesThisWeek = useSelector(getTotalExpensesThisWeek);
  const expensesYearToDate = useSelector(getTotalExpensesYearToDate);
  const incomeThisWeek = useSelector(getTotalIncomeThisWeek);
  const incomeYearToDate = useSelector(getTotalIncomeYearToDate);

  // React - useEffect
  React.useEffect(() => {
    dispatch<any>(doGetExpenses());
  }, []);

  return (
    <div className="lumanti-home_income_expense">
      <CardIncome
        label="Weekly Sales"
        navigateTo="orders"
        value={convertCentsToDollar(incomeThisWeek)}
      />
      <CardExpense
        label="Weekly Expenses"
        navigateTo="expenses"
        value={formatCurrency(expensesThisWeek)}
      />
      <CardIncome
        label="Year to date Sales"
        navigateTo="orders"
        value={convertCentsToDollar(incomeYearToDate)}
      />
      <CardExpense
        label="Year to date Expenses"
        navigateTo="expenses"
        value={formatCurrency(expensesYearToDate)}
      />
    </div>
  );
};

export { HomeIncomeExpense };
