// Globals
import './styles.scss';
import React from 'react';

// Components
import { Accordion } from 'components/Accordion';
import { AriaLink } from 'components/AriaLink';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Paragraph } from 'components/Paragraph';

// Misc
import clsx from 'clsx';
import { convertCentsToDollar } from 'utils/orders';
import { format } from 'date-fns';
import { OrderListBody } from '../index';

interface OrdersListProps {
  orderList: OrderListBody[];
  handleDeleteOrder: (id: string) => void;
  handleUpdateModal: (order: any) => void;
}

const RenderAccordionTopBar: React.FC<{
  date: string;
  name: string;
  total: string;
  phoneNumber: string;
}> = ({ date, name, total, phoneNumber }) => {
  return (
    <div className="lumanti-orders-top-bar">
      <Heading size={16} type="h4">
        {date ? format(new Date(date), 'MMM dd') : ''}
      </Heading>
      <Heading size={16} type="h4">
        {name ? name : phoneNumber}
      </Heading>
      <Heading size={16} type="h4">
        ${convertCentsToDollar(total)}
      </Heading>
    </div>
  );
};

const RenderAccordionBody: React.FC<OrderListBody> = ({
  address,
  discountAmount,
  orderDetails,
  delivery,
  phoneNumber,
  subTotal,
  taxAmount,
  totalAmount,
  paymentMethod,
  paymentStatus
}) => {
  return (
    <div className="lumanti-orders-accordion-body">
      {(address || phoneNumber) && (
        <div className="lumanti-orders-accordion-body-contact">
          {address && (
            <Heading size={16} type="h4">
              <Icon name="home" />{' '}
              <AriaLink href={`https://waze.com/ul?q=${address}`}>{address}</AriaLink>
            </Heading>
          )}
          {phoneNumber && (
            <Heading size={16} type="h4">
              <Icon name="phone" /> {phoneNumber}
            </Heading>
          )}
        </div>
      )}

      {/* Order Item List */}
      <div className="lumanti-orders-accordion-body-lists">
        <div className="lumanti-orders-body-list">
          <Heading size={16} type="h4">
            Item
          </Heading>
          <Heading size={16} type="h4">
            Size
          </Heading>
          <Heading size={16} type="h4">
            QTY
          </Heading>
          <Heading size={16} type="h4">
            Price
          </Heading>
        </div>
        {orderDetails.map((orderDetail, index) => {
          return (
            <div
              className="lumanti-orders-body-list"
              key={`${orderDetail.itemName}${String(index)}`}
            >
              <Paragraph>{orderDetail.itemName}</Paragraph>
              <Paragraph>{orderDetail.itemSize ?? 'N/A'}</Paragraph>
              <Paragraph>{orderDetail.itemQty}</Paragraph>
              <Paragraph>${orderDetail.itemPrice}</Paragraph>
            </div>
          );
        })}
      </div>

      {/* Amount Summary */}
      <div className="lumanti-orders-accordion-body-summary-container">
        <div className="lumanti-orders-accordion-body-summary">
          <Heading size={16} type="h4">
            Sub Total:
          </Heading>
          <Paragraph>${convertCentsToDollar(subTotal)}</Paragraph>
        </div>

        {Number(discountAmount) > 0 && (
          <div className="lumanti-orders-accordion-body-summary">
            <Heading size={16} type="h4">
              Discount:
            </Heading>
            <Paragraph>${convertCentsToDollar(discountAmount)}</Paragraph>
          </div>
        )}

        {Number(taxAmount) > 0 && (
          <div className="lumanti-orders-accordion-body-summary">
            <Heading size={16} type="h4">
              Tax:
            </Heading>
            <Paragraph>${convertCentsToDollar(taxAmount)}</Paragraph>
          </div>
        )}

        {delivery && (
          <div className="lumanti-orders-accordion-body-summary">
            <Heading size={16} type="h4">
              Delivery:
            </Heading>
            <Paragraph>${delivery}</Paragraph>
          </div>
        )}

        <div className="lumanti-orders-accordion-body-summary">
          <Heading size={16} type="h4">
            Total:
          </Heading>
          <Paragraph>${convertCentsToDollar(totalAmount)}</Paragraph>
        </div>
      </div>

      {/* Payment Summary */}
      <div className="lumanti-orders-accordion-body-payment-summary-container">
        <Heading size={16} type="h4">
          Payment Status: {paymentStatus}
        </Heading>
        <Heading size={16} type="h4">
          Payment Method: {paymentMethod}
        </Heading>
      </div>
    </div>
  );
};

// Component
const OrdersList: React.FC<OrdersListProps> = ({
  orderList,
  handleDeleteOrder,
  handleUpdateModal
}) => {
  return (
    <div className="lumanti-orders-lists">
      {orderList.length > 0 &&
        orderList.map((order) => {
          const classes = clsx('lumanti-orders-accordion', {
            'lumanti-orders-accordion-paid': order.paymentStatus === 'paid',
            'lumanti-orders-accordion-unpaid':
              order.paymentStatus === 'unpaid' && new Date(order.serviceDate) < new Date()
          });
          const options =
            order.paymentStatus === 'unpaid' || new Date(order.serviceDate) >= new Date()
              ? [
                  { label: 'Edit', cta: () => handleUpdateModal(order) },
                  { label: 'Delete', cta: () => handleDeleteOrder(order.id) }
                ]
              : [];

          return (
            <div className="lumanti-orders-list" key={order.id}>
              <Accordion
                className={classes}
                moreMenuOptions={options}
                topBar={
                  <RenderAccordionTopBar
                    date={order.serviceDate}
                    name={order.name}
                    phoneNumber={order.phoneNumber}
                    total={order.totalAmount}
                  />
                }
              >
                <RenderAccordionBody {...order} />
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export { OrdersList };
