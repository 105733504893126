// Globals
import './styles.scss';
import React from 'react';

// Services
import { getOrdersFilter } from 'services/orders/selectors';
import { setFilter } from 'services/orders/reducers';

// Mui
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

// Misc
import { LmtDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';

const toggleSelectClasses = {
  color: '#adceff',
  borderColor: '#1d2f50',
  backgroundColor: '#121e2d',
  '&:active': { backgroundColor: '#6694ff' }
};

// Component
const OrdersFilters: React.FC = () => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  // Hooks - selectors
  const filterDate = useSelector(getOrdersFilter);

  // Handler
  const handleFilterDateChange = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    void dispatch(setFilter(newFilter));
  };
  return (
    <div className="lumanti_orders_filters">
      <ToggleButtonGroup
        color="primary"
        exclusive
        onChange={handleFilterDateChange}
        value={filterDate}
      >
        <ToggleButton sx={toggleSelectClasses} value="all">
          All
        </ToggleButton>
        <ToggleButton sx={toggleSelectClasses} value="today">
          Today
        </ToggleButton>
        <ToggleButton sx={toggleSelectClasses} value="week">
          This Week
        </ToggleButton>
        <ToggleButton sx={toggleSelectClasses} value="month">
          This Month
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export { OrdersFilters };
