import { combineReducers } from '@reduxjs/toolkit';
import {
  AllReportAction,
  DateRangeFilter,
  initialDateRangeFilter,
  ReportsActionTypes,
  ReportsState
} from './types';

export const setDateRange = (data: DateRangeFilter) => ({
  type: ReportsActionTypes.SET_DATE_RANGE_FILTER,
  data
});

export function dateRangeFilter(
  dateRangeFilter: DateRangeFilter = initialDateRangeFilter,
  action: AllReportAction
) {
  switch (action.type) {
    case ReportsActionTypes.SET_DATE_RANGE_FILTER:
      return action.data;
    default:
      return dateRangeFilter;
  }
}

// Root Reducer
export const reports = combineReducers<ReportsState>({
  dateRangeFilter
});
