import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserCredential
} from 'firebase/auth';
import { auth, googleProvider } from 'config/firebase';
import { LmtThunk } from 'store/types';
import { clearToken, setError, setUser } from './reducers';
import { LoginAuthParams } from './types';

export function doSignInWithGoogle(): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    const resp: UserCredential = await signInWithPopup(auth, googleProvider);
    dispatch(setUser(resp));
  };
}

export function doSignInWithEmail(data: LoginAuthParams): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      const resp: UserCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      dispatch(setUser(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function doSignOut(): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    await signOut(auth);
    dispatch(clearToken());
  };
}
