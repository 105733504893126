import './styles.scss';
import React from 'react';

// Components
import { Card } from 'components/Card';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

const HomeWelcome: React.FC = () => {
  return (
    <Card className="lumanti-home_welcome">
      <div className="lumanti-home_welcome-card-description">
        <Heading size={22} type="h3">
          Welcome Lumanti!
        </Heading>
        <Paragraph size={18}>
          From this dashboard, you&apos;ll be able to track key metrics, With these insights at your
          fingertips, you can refine your strategies.
        </Paragraph>
      </div>
      <img
        alt="lumanti"
        className="lumanti-home_welcome-illustration"
        src={require('assets/media/illustration/illustration-person-sitting.png')}
      />
    </Card>
  );
};

export { HomeWelcome };
