// Globals
import './styles.scss';
import 'theme/basel-grotesk/styles.scss'; // Font styles
import React from 'react';

// Components
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Router } from 'components/Router';

// Misc
import store from './store';
import { routes } from './routes';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Router routes={routes} />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
