import React from 'react';

export enum ButtonType {
  BUTTON = 'button',
  ICON = 'icon',
  SUBMIT = 'submit'
}

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  badge?: JSX.Element;
  display?: 'inline';
  form?: string;
  goto?: string;
  hoverText?: string;
  icon?: string;
  id?: string;
  intent?: 'primary' | 'secondary' | 'text';
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  trackingDisabled?: boolean;
  type?: 'button' | 'icon' | 'submit';
}
