// Global
import './styles.scss';
import React from 'react';

// Mui
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';

// Types
import { OrderInfo, textFieldClasses } from '../types';

interface ModalUpdateOrderInfoProps {
  orderInfo: OrderInfo;
  setOrderInfo: (orderInfo: OrderInfo) => void;
}

// Component
const ModalUpdateOrderInfo: React.FC<ModalUpdateOrderInfoProps> = ({ orderInfo, setOrderInfo }) => {
  // Vars
  const orderNumber = new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

  // Handler
  const handleOnChange = (value: string, field: keyof OrderInfo) => {
    setOrderInfo({ ...orderInfo, [field]: value });
  };

  return (
    <Box sx={textFieldClasses}>
      <div className="lumanti-modal_update_order_info">
        <TextField
          InputProps={{ readOnly: true }}
          defaultValue={orderNumber}
          id="outlined-read-only-input"
          label="Order Number"
          name="orderNumber"
        />
        <TextField
          id="outlined-required"
          label="Service Date"
          name="serviceDate"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'serviceDate')}
          value={orderInfo.serviceDate}
        />
        <TextField
          id="outlined-required"
          label="Service Time"
          name="serviceTime"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'serviceTime')}
          value={orderInfo.serviceTime}
        />
        <TextField
          id="outlined-required"
          label="Name"
          name="name"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'name')}
          value={orderInfo.name}
        />
        <TextField
          id="outlined-required"
          label="Address"
          name="address"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'address')}
          value={orderInfo.address}
        />
        <TextField
          id="outlined-required"
          label="Phone"
          name="phoneNumber"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'phoneNumber')}
          value={orderInfo.phoneNumber}
        />
      </div>
    </Box>
  );
};

export { ModalUpdateOrderInfo };
