// Action types
export enum ExpensesActionTypes {
  SET_EXPENSES = 'lumanti.Expenses.SET_EXPENSES',
  SET_FILTER = 'lumanti.Expenses.SET_FILTER'
}

// Generic Expenses actions
export interface ExpensesAction<P> {
  type: ExpensesActionTypes;
  data: P;
}

export interface ExpensesSetExpensesAction extends ExpensesAction<any> {
  type: ExpensesActionTypes.SET_EXPENSES;
}

export interface ExpensesSetExpensesFilter extends ExpensesAction<any> {
  type: ExpensesActionTypes.SET_FILTER;
}

export type AllExpensesAction = ExpensesSetExpensesAction | ExpensesSetExpensesFilter;

export interface ExpensesState {
  list: ExpenseList[];
  filter: string;
}

export interface ExpenseList {
  amount: string;
  category: string;
  merchant: string;
  receiptDate: string;
  cardOwner: 'personal' | 'business';
}
