import * as React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Modal } from 'components/Modal';
import { ModalUpdateOrderDetails } from './Details';
import { ModalUpdateOrderInfo } from './Info';
import { ModalUpdateOrderMisc } from './Misc';
import { ModalBodyText } from 'components/Modal/BodyText';
import { Paragraph } from 'components/Paragraph';

// Services
import { doUpdateOrder } from 'services/orders/async';

// Types
import {
  ModalAddOrderV2Props,
  ModalUpdateOrderProps,
  OrderDetails,
  orderDetailsInitial,
  OrderInfo,
  orderInfoInitial,
  OrderMisc,
  orderMiscInitial,
  paymentInfoInitial,
  stepperClasses
} from './types';

// MUI
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

// Misc
import {
  calculateDiscount,
  calculateTax,
  calculateTotal,
  convertCentsToDollar,
  convertDollarToCents
} from 'utils/orders';
import { LmtDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { PaymentInfo } from './types';
import { ModalUpdateOrderPaymentInfo } from './PaymentInfo';

const steps = [
  { label: 'Order Info', description: '' },
  { label: 'Order Details', description: '' },
  { label: 'Misc', description: '' },
  { label: 'Payment Info', description: '' }
];

const ModalUpdateOrder: React.FC<ModalUpdateOrderProps> = ({
  isVisible,
  hide,
  orderId,
  updateOrderInfo,
  updateOrderDetails,
  updateOrderMisc,
  updatePaymentInfo
}) => {
  const [subTotal, setSubTotal] = React.useState<any>('');
  const [taxAmount, setTaxAmount] = React.useState('');
  const [totalAmount, setTotalAmount] = React.useState('');
  const [discount, setDiscount] = React.useState({ discountPercent: '', discountAmount: '' });

  const [orderInfo, setOrderInfo] = React.useState<OrderInfo>(orderInfoInitial);
  const [orderList, setOrderList] = React.useState<OrderDetails[]>(orderDetailsInitial);
  const [orderMisc, setOrderMisc] = React.useState<OrderMisc>(orderMiscInitial);
  const [paymentInfo, setPaymentInfo] = React.useState<PaymentInfo>(paymentInfoInitial);

  const [activeStep, setActiveStep] = React.useState(0);
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  React.useEffect(() => {
    setActiveStep(0);
  }, [isVisible]);

  React.useEffect(() => {
    setOrderInfo(updateOrderInfo);
    setOrderList(updateOrderDetails);
    setOrderMisc(updateOrderMisc);
    setPaymentInfo(updatePaymentInfo);
  }, [updateOrderInfo, updateOrderDetails, updateOrderMisc, updatePaymentInfo]);

  // Hooks - useEffect
  // Sub-Total
  React.useEffect(() => {
    calculateOrderSubTotal();
  }, [orderList]);

  // Discount
  React.useEffect(() => {
    const discount = calculateDiscount(subTotal, orderMisc.discount);
    setDiscount({ discountPercent: orderMisc.discount, discountAmount: discount });
  }, [orderMisc, subTotal]);

  // Tax
  React.useEffect(() => {
    const taxPercent = Number(orderMisc.tax) ?? 0;
    const priceBeforeTax = Number(subTotal) - Number(discount.discountAmount);
    const taxAmount = calculateTax(priceBeforeTax, taxPercent);
    setTaxAmount(taxAmount);
  }, [subTotal, discount]);

  // Total
  React.useEffect(() => {
    const deliveryAmount = convertDollarToCents(orderMisc.delivery);
    const total = calculateTotal(subTotal, discount.discountAmount, deliveryAmount, taxAmount);
    setTotalAmount(total);
  }, [subTotal, discount, orderMisc, taxAmount]);

  // Vars
  const currentDate = new Date().toLocaleDateString('en-US');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const calculateOrderSubTotal = () => {
    let subTotal = 0;

    orderList.map((order) => {
      const orderQty = order.itemQty;
      const orderItemPrice = convertDollarToCents(order.itemPrice);
      const total = Number(orderQty) * Number(orderItemPrice);
      subTotal += total;
    });
    setSubTotal(subTotal);
  };

  // Form Submission Handler
  const onFormSubmit = () => {
    const formSubmissionValues = {
      ...orderInfo,
      ...orderMisc,
      ...paymentInfo,
      dateCreated: currentDate,
      discountAmount: discount.discountAmount,
      orderDetails: orderList,
      subTotal,
      taxAmount,
      totalAmount
    };
    dispatch<any>(doUpdateOrder(orderId, formSubmissionValues));
    hide();
  };

  return (
    <Modal className="lumanti-modal_update_order" hide={hide} isVisible={isVisible}>
      <ModalBodyText>
        <div className="lumanti-modal_update_order-body">
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} sx={stepperClasses}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  {index === 0 && (
                    <ModalUpdateOrderInfo orderInfo={orderInfo} setOrderInfo={setOrderInfo} />
                  )}
                  {index === 1 && (
                    <ModalUpdateOrderDetails orderList={orderList} setOrderList={setOrderList} />
                  )}
                  {index === 2 && (
                    <ModalUpdateOrderMisc orderMisc={orderMisc} setOrderMisc={setOrderMisc} />
                  )}
                  {index === 3 && (
                    <ModalUpdateOrderPaymentInfo
                      paymentInfo={paymentInfo}
                      setPaymentInfo={setPaymentInfo}
                    />
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div className="lumanti-modal_update_order-nav-btns">
                      <Button intent="primary" onClick={handleNext}>
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button intent="text" isDisabled={index === 0} onClick={handleBack}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper
              elevation={0}
              square
              sx={{ p: 3, backgroundColor: '#121e2d', borderRadius: '14px' }}
            >
              <Heading size={16} type="h3">
                All steps completed - Click Add Order to create an order
              </Heading>
              <div className="lumanti-modal_update_order-confirmation-button">
                <Button
                  icon="back"
                  intent="secondary"
                  onClick={() => setActiveStep(steps.length - 1)}
                  type="icon"
                >
                  Go Back
                </Button>
                <Button onClick={onFormSubmit}>Update order</Button>
              </div>
            </Paper>
          )}
        </div>

        <div className="lumanti-modal_update_order-summary">
          <div className="lumanti-modal_update_order-summary-details">
            <div className="lumanti-modal_update_order-summary-row">
              <Heading size={14} type="h4">
                Subtotal:
              </Heading>
              <Paragraph size={14}>${convertCentsToDollar(subTotal)}</Paragraph>
            </div>

            <div className="lumanti-modal_update_order-summary-row">
              <Heading size={14} type="h4">
                Discount:
              </Heading>
              <Paragraph size={14}>${convertCentsToDollar(discount.discountAmount)}</Paragraph>
            </div>

            <div className="lumanti-modal_update_order-summary-row">
              <Heading size={14} type="h4">
                Tax:
              </Heading>
              <Paragraph size={14}>${convertCentsToDollar(taxAmount)}</Paragraph>
            </div>

            {orderMisc.delivery && (
              <div className="lumanti-modal_update_order-summary-row">
                <Heading size={14} type="h4">
                  Delivery:
                </Heading>
                <Paragraph size={14}>
                  {Number(orderMisc.delivery).toLocaleString('en-us', {
                    style: 'currency',
                    currency: 'usd'
                  })}
                </Paragraph>
              </div>
            )}
          </div>
          <Heading className="lumanti-modal_update_order-summary-total" size={32} type="h3">
            ${convertCentsToDollar(totalAmount)}
          </Heading>
        </div>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalUpdateOrder };
