// Globals
import './styles.scss';
import React from 'react';

// Component
import { Button } from 'components/Button';

// Services
import { clearError } from 'services/auth/reducers';
import { doSignInWithEmail } from 'services/auth/async';
import { getAuthError } from 'services/auth/selectors';

// Misc
import { Form } from 'components/Form';
import { Input } from 'components/Input';
import { LmtDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Assets
// @ts-ignore
import lumantiLogo from 'assets/media/lumanti-logo-text.png';
// @ts-ignore
import googleLogo from 'assets/media/google.png';

// Component
const SignIn: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch: LmtDispatch = useDispatch();

  // Hooks - selector
  const authErrorMessage = useSelector(getAuthError);

  React.useEffect(() => {
    dispatch(clearError());
  }, []);

  // Handlers
  const onSubmit = async (formData: any) => {
    await dispatch<any>(doSignInWithEmail(formData));
    navigate('/');
  };

  // Render
  return (
    <div className="lumanti-sign_in">
      <div className="lumanti-sign_in-container">
        <img alt="lumanti" className="lumanti-sign_in-logo" src={lumantiLogo} />

        <Form
          className="lumanti-sign_in-form"
          error={authErrorMessage}
          id="lumanti-sign-in"
          name="sign-in"
          onSubmit={onSubmit}
        >
          <Input name="email" placeholder="Email" type="text" />
          <Input name="password" placeholder="Password" type="password" />

          <Button className="lumanti-sign_in-button" isFullWidth type="submit">
            Sign In
          </Button>
        </Form>
      </div>
    </div>
  );
};

export { SignIn };
