// Globals
import './styles.scss';
import React from 'react';

interface AvatarProps {
  type: 'icon' | 'badge';
  firstName?: string;
}

// Component
import { Heading } from 'components/Heading';

const Avatar: React.FC<AvatarProps> = ({ type, firstName = '' }) => {
  const initial = firstName?.charAt(0).toUpperCase();

  if (type === 'badge') {
    return <div className="lumanti-avatar">{initial}</div>;
  }

  return <img alt="avatar in circle" src={require('assets/media/avatar/avatar_neutral.png')} />;
};

export { Avatar };
