// Global
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Card } from 'components/Card';

// Mui
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';

// Types
import { textFieldClasses } from '../types';
import { OrderDetails } from './types';

interface ModalUpdateOrderDetailsProps {
  orderList: OrderDetails[];
  setOrderList: (orderList: OrderDetails[]) => void;
}

// Component
const ModalUpdateOrderDetails: React.FC<ModalUpdateOrderDetailsProps> = ({
  orderList,
  setOrderList
}) => {
  // handle click event of the Add button
  const handleAddClick = () => {
    setOrderList([...orderList, { itemName: '', itemSize: '', itemQty: '', itemPrice: '' }]);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...orderList];
    list.splice(index, 1);
    setOrderList(list);
  };

  // Order Input Handlers
  // Handle input change
  const handleInputChange = (e: React.ChangeEvent, index: number) => {
    const { name, value } = e.target as HTMLInputElement;
    const list: OrderDetails[] = [...orderList];
    orderList[index][name as keyof OrderDetails] = value;
    setOrderList(list);
  };

  return (
    <div className="lumanti-modal_update_order_details">
      {orderList.map((order, index) => {
        return (
          <Box key={`${order}${index}`} sx={textFieldClasses}>
            <Card className="lumanti-modal_update_order_details-orders">
              <div className="lumanti-modal_update_order_details-order">
                <TextField
                  id="outlined-required"
                  label="Item Name"
                  name="itemName"
                  onChange={(e) => handleInputChange(e, index)}
                  value={order.itemName}
                />
                <TextField
                  id="outlined-required"
                  label="Item Size"
                  name="itemSize"
                  onChange={(e) => handleInputChange(e, index)}
                  value={order.itemSize}
                />
                <TextField
                  id="outlined-required"
                  label="Qty"
                  name="itemQty"
                  onChange={(e) => handleInputChange(e, index)}
                  value={order.itemQty}
                />
                <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    label="Amount"
                    name="itemPrice"
                    onChange={(e) => handleInputChange(e, index)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    value={order.itemPrice}
                  />
                </FormControl>
              </div>
              <div className="lumanti-modal_update_order_details-order-cta">
                <Button
                  className="lumanti-modal_update_order_details-delete-btn"
                  icon="delete"
                  isDisabled={orderList.length === 1}
                  onClick={() => handleRemoveClick(index)}
                  type="icon"
                />
              </div>
            </Card>
          </Box>
        );
      })}

      <div className="lumanti-modal_update_order_details-add-btn">
        <Button icon="plus" intent="primary" onClick={handleAddClick} type="icon">
          Add item
        </Button>
      </div>
    </div>
  );
};

export { ModalUpdateOrderDetails };
