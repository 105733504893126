import { includes } from 'lodash';

export const vendors: string[] = [
  'costco',
  'cvs',
  'dollar_tree',
  'enterprise',
  'exxon',
  'giant',
  'hmart',
  'home_depot',
  'lotte',
  'lowes',
  'michaels',
  'patel_bros',
  'restaurant_depot',
  'seven_eleven',
  'sheel',
  'sheetz',
  'uber',
  'uhaul',
  'walmart',
  'wawa',
  'wegmans'
];

const checkIfImageExists = (vendor: string) => {
  return includes(vendors, vendor);
};

export { checkIfImageExists };
