// Globals
import './styles.scss';
import React from 'react';

// MUI
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Misc
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface ReportsDatesProps {
  dateEnd: any;
  dateStart: any;
  setDateEnd: (date: any) => void;
  setDateStart: (date: any) => void;
}

// Component
const ReportsDates: React.FC<ReportsDatesProps> = ({
  dateEnd,
  dateStart,
  setDateEnd,
  setDateStart
}) => {
  return (
    <div className="lumanti-reports_dates">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          onChange={(newValue) => setDateStart(dayjs(newValue))}
          slotProps={{ textField: { size: 'medium' } }}
          value={dateStart as any}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="End Date"
          onChange={(newValue) => setDateEnd(dayjs(newValue))}
          slotProps={{ textField: { size: 'medium' } }}
          value={dateEnd as any}
        />
      </LocalizationProvider>
    </div>
  );
};

export { ReportsDates };
