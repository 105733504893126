// Globals
import './styles.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import { Icon } from 'components/Icon';
import { InputSelectOption } from './Option';

// Misc
import clsx from 'clsx';
import { getWindow } from 'ssr-window';
import { InputSelectChangeEvent, InputSelectProps } from './types';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';

// Constants
const deletionKeys: Record<string, string> = {
  Backspace: 'Backspace',
  Delete: 'Delete'
};

// Component
const InputSelect: React.FC<InputSelectProps> = ({
  className,
  isDisabled,
  inputId,
  inputLabel,
  name,
  onInput,
  onChange,
  options = [],
  placeholderIsDisabled,
  placeholder,
  value
}) => {
  const window = getWindow();

  // Hooks - state
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [typedValue, setTypedValue] = useState('');
  const [closestMatchLabel, setClosestMatchLabel] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [onInputEvent, setOnInputEvent] = useState<InputSelectChangeEvent>();

  // Hooks - refs
  const inputSelect = useRef<HTMLUListElement>(null);
  const isOpenRef = useRef(isOpen);
  const timer = useRef<LmtTimeout>();

  // Util
  const setIsOpenRef = (isOpen: boolean) => {
    isOpenRef.current = isOpen;
    setIsOpen(isOpen);
  };

  // Handlers
  const closeMenu = useCallback((event: MouseEvent) => {
    if (inputSelect?.current?.contains(event.target as HTMLElement) || !isOpenRef.current) {
      return;
    }

    setIsOpenRef(false);
    event.stopPropagation();
  }, []);

  const selectOption = useCallback(
    (label = '', inputValue: string, event?: InputSelectChangeEvent) => {
      // In case of manual autofill, event may not exist
      if (event && onChange) {
        onChange({ ...event, target: { ...event.target, value: value ?? '' } });
      }
      setLabel(label);
      resetAutocomplete();
      setIsOpenRef(false);
      setStateValue((prevValue) => {
        // Mock some basic event data for onInput since this component is not an actual input
        if (prevValue !== inputValue && event) {
          event.autocomplete = {};
          event.autocomplete.value = inputValue;
          event.autocomplete.name = name;
          // Set event in state so onInput can be invoked after setValue update, preventing 'bad setState' update from Form re-rendering simultaneously
          setOnInputEvent(event);
        }
        return inputValue;
      });
      const inputSelected: HTMLDivElement | null | undefined = inputSelect?.current?.querySelector(
        '.lumanti-input_select-selected'
      );
      inputSelected?.focus();
    },
    [value, onChange, name]
  );

  const autoComplete = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const item = options.find((option) => option.label === value || option.value === value);

    if (!item) {
      return;
    }

    selectOption(item.label, item.value, event);
  };

  const resetAutocomplete = () => {
    setTypedValue('');
    setClosestMatchLabel('');
  };

  const toggleMenu = () => {
    if (isDisabled) return;

    if (timer.current) {
      clearTimeout(timer.current);
    }

    setIsOpenRef(!isOpen);
    resetAutocomplete();
  };

  /**
   * Try to find option element that matches the selected label
   * if success, focus on matched element
   * else, focus on first element of options (which is placeholder option)
   */
  const focusOptionElement = useCallback(() => {
    if (!inputSelect?.current) return;

    const matchedElement: HTMLDivElement | null = inputSelect.current.querySelector(
      `[data-label^="${label.toLowerCase()}"]`
    );

    if (matchedElement) {
      matchedElement.focus();
    } else {
      const selectList = inputSelect?.current?.querySelector('.lumanti-input_select-list');
      const elements: NodeListOf<HTMLDivElement> | undefined =
        selectList?.querySelectorAll('div:not([disabled])');
      if (elements?.length) {
        elements[0].focus();
      }
    }
  }, [label]);

  const typeToFind = (event: React.KeyboardEvent<HTMLUListElement>) => {
    const key = event.key;
    /**
     * Prevent key press of any special keys
     * Only allowed keys are alphanumeric, special chars, space and deletion keys
     */
    if (!deletionKeys[key] && key.length > 1) {
      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    let updatedTypedValue = typedValue ?? '';
    // handle backspace/delete
    if (deletionKeys[key]) {
      updatedTypedValue = typedValue.slice(0, -1);
    } else {
      updatedTypedValue += key;
    }

    const closestMatch: HTMLDivElement | null | undefined = inputSelect?.current?.querySelector(
      `[data-label^="${updatedTypedValue.toLowerCase()}"]`
    );

    if (closestMatch) {
      closestMatch.focus();
      const updatedClosestMatchLabel: string | null =
        closestMatch.firstChild?.nodeValue ?? closestMatch.getAttribute('data-label') ?? '';
      const closestMatchValue = closestMatch.getAttribute('value') ?? '';
      setClosestMatchLabel(updatedClosestMatchLabel);

      if (!isOpen && closestMatchValue !== stateValue) {
        // @ts-ignore
        selectOption(updatedClosestMatchLabel, closestMatchValue, event);
      }
    } else if (isOpen) {
      focusOptionElement();
      setClosestMatchLabel(placeholder ?? '');
    }

    setTypedValue(updatedTypedValue);
    timer.current = setTimeout(() => {
      resetAutocomplete();
    }, 7000);
  };

  // Hooks - effects
  useEffectDidUpdate(() => {
    if (onInput && onInputEvent) onInput(onInputEvent);
  }, [onInputEvent]);

  useEffect(() => {
    window.addEventListener('click', closeMenu, true);
  }, [closeMenu, window]);

  useEffect(() => {
    return () => {
      window.removeEventListener('click', closeMenu, true);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [closeMenu, window]);

  // Select autocompleted via props update
  useEffect(() => {
    const label = value ? options.find((option) => option.value === value)?.label : placeholder;
    setLabel(label ?? '');

    if (value && isOpen) {
      selectOption(label, value);
    }
    // deps array: dont include selectOption because it makes dropdown close
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholder, options, value]);

  useEffect(() => {
    if (isOpen) {
      focusOptionElement();
    }
  }, [isOpen, focusOptionElement]);

  // Vars
  const inputBoxClasses = clsx([className, 'lumanti-input_select-selected'], {
    'lumanti-input_select-selected-open': isOpen,
    'lumanti-input_select-selected-active': stateValue || closestMatchLabel !== '',
    'lumanti-input_select-selected-disabled': isDisabled
  });
  const listContainerClasses = clsx('lumanti-input_select-list', {
    'lumanti-input_select-list-hidden': !isOpen
  });
  const inputBoxLabel = closestMatchLabel === '' ? label : closestMatchLabel;

  // Render
  return (
    <ul
      aria-labelledby={inputLabel}
      className="lumanti-input_select"
      onKeyDown={typeToFind}
      ref={inputSelect}
      role="presentation"
    >
      {/* Input box */}
      <li>
        <div
          className={inputBoxClasses}
          id={inputId}
          onClick={toggleMenu}
          role="button"
          tabIndex={0}
        >
          {inputBoxLabel}
          <Icon
            className="lumanti-input_select-caret"
            isDisabled={isDisabled}
            name="dropdown"
            title="Dropdown"
          />
        </div>
      </li>
      {/* Options container */}
      <div className={listContainerClasses}>
        {/* Placeholder */}
        <InputSelectOption
          disabled={placeholderIsDisabled}
          label={placeholder}
          selectOption={selectOption}
          selectedLabel={label}
          tabIndex={placeholderIsDisabled ? -1 : 0}
          value=""
        >
          {placeholder}
        </InputSelectOption>
        {/* Options */}
        {options.map((item, index) => {
          return (
            <InputSelectOption
              key={item.label}
              label={item.label}
              selectOption={selectOption}
              selectedLabel={label}
              tabIndex={index}
              value={item.value}
            >
              {item.label}
            </InputSelectOption>
          );
        })}
      </div>
      {/* Hidden input used to capture autoComplete values */}
      <input
        autoComplete="on"
        onChange={autoComplete}
        style={{
          position: 'absolute',
          zIndex: '-1',
          opacity: '0'
        }}
        tabIndex={-1}
      />
    </ul>
  );
};

export { InputSelect };
