import dayjs, { Dayjs } from 'dayjs';

export const initialExpenseInfo = {
  amount: '',
  category: '',
  merchant: '',
  receiptDate: dayjs(new Date()),
  cardOwner: ''
};

export interface ExpenseInfo {
  amount: string;
  category: string;
  merchant: string;
  receiptDate: Dayjs | null;
  cardOwner: string;
}
