// Global
import './styles.scss';
import React from 'react';

// Mui
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';

// Types
import { OrderInfo } from '../types';

// Misc
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { textFieldClasses } from 'utils/MuiClasses';

interface ModalAddOrderV2OrderInfoProps {
  orderInfo: OrderInfo;
  setOrderInfo: (orderInfo: OrderInfo) => void;
}

// Component
const ModalAddOrderV2OrderInfo: React.FC<ModalAddOrderV2OrderInfoProps> = ({
  orderInfo,
  setOrderInfo
}) => {
  // Vars
  const orderNumber = new Date().getTime().toString(36) + new Date().getUTCMilliseconds();

  // Handler
  const handleOnChange = (value: string, field: keyof OrderInfo) => {
    setOrderInfo({ ...orderInfo, [field]: value });
  };

  return (
    <Box sx={textFieldClasses}>
      <div className="lumanti-modal_add_order_v2_order_info">
        <TextField
          InputProps={{ readOnly: true }}
          defaultValue={orderNumber}
          id="outlined-read-only-input"
          label="Order Number"
          name="orderNumber"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Receipt Date"
            onChange={(newValue) =>
              handleOnChange(dayjs(newValue).format('MM/DD/YYYY'), 'serviceDate')
            }
            slotProps={{ textField: { size: 'medium' } }}
            value={orderInfo.serviceDate ?? dayjs(new Date())}
          />
        </LocalizationProvider>
        <TextField
          id="outlined-required"
          label="Service Time"
          name="serviceTime"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'serviceTime')}
          value={orderInfo.serviceTime}
        />
        <TextField
          id="outlined-required"
          label="Name"
          name="name"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'name')}
          value={orderInfo.name}
        />
        <TextField
          id="outlined-required"
          label="Address"
          name="address"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'address')}
          value={orderInfo.address}
        />
        <TextField
          id="outlined-required"
          label="Phone"
          name="phoneNumber"
          onChange={(e) => handleOnChange((e.target as HTMLInputElement).value, 'phoneNumber')}
          value={orderInfo.phoneNumber}
        />
      </div>
    </Box>
  );
};

export { ModalAddOrderV2OrderInfo };
