// Globals
import React from 'react';

// Components
import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesContext } from './context';

// Types
import { RouterProps, RouterRouteProps } from './types';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'services/auth/selectors';

/*
 react-router-dom 6
 <Routes> instead of <Switch>
 <Navigate> instead of <Redirect>
 element instead of render
 */

const Router: React.FC<RouterProps> = ({ routes }) => {
  const isAuthenticated = useSelector(getAccessToken);

  return (
    <Routes>
      {routes.map((routeData: RouterRouteProps, index: number) => {
        const { component: Component, path, auth, ...rest } = routeData;

        if (auth && !isAuthenticated) {
          return <Route element={<Navigate to="/sign-in" />} key={path} path={path} />;
        }

        return (
          <Route
            element={
              <RoutesContext.Provider value={{ auth: Boolean(auth) }}>
                <Component {...rest} />
              </RoutesContext.Provider>
            }
            key={`${String(path)}${index}`}
            path={path}
          />
        );
      })}
    </Routes>
  );
};

export { Router };
