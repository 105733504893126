/* eslint react/button-has-type: 0 */

// Globals
import './styles.scss';
import React from 'react';

// Components
import { Icon } from 'components/Icon';
import { Loading } from 'components/Loading';

// Misc
import clsx from 'clsx';
import { ButtonTextProps } from './types';

// Component
const ButtonText: React.FunctionComponent<ButtonTextProps> = ({
  children,
  className,
  disabled,
  display,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hoverText, // Destructure unnecessary props
  icon,
  intent = 'primary',
  isFullWidth,
  isLoading,
  type,
  ...otherProps
}) => {
  // native HTML <button> "type" attribute does not take value "icon";
  // default to "button" for everything other than "submit"
  const buttonType = type === 'submit' ? 'submit' : 'button';

  const classes = clsx('lumanti-button_text', className, {
    'lumanti-button_text-primary': intent === 'primary',
    'lumanti-button_text-secondary': intent === 'secondary',
    'lumanti-button_text-text': intent === 'text',
    'lumanti-button_text-display-inline': display === 'inline',
    'lumanti-button_text-full-width': isFullWidth,
    'lumanti-button_text-loading': isLoading,
    'lumanti-button_text-loading-secondary': isLoading && intent === 'secondary'
  });

  const renderChildren = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <>
        {/* Icon always on the left per design system */}
        {icon && <Icon name={icon} />}
        {children}
      </>
    );
  };

  return (
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    <button className={classes} {...otherProps} disabled={disabled || isLoading} type={buttonType}>
      {renderChildren()}
    </button>
  );
};

export { ButtonText };
