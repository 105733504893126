// Globals
import './styles.scss';
import * as React from 'react';

// Components
import { Paragraph } from 'components/Paragraph';
import { Avatar } from 'components/Avatar';

// Types
import { TimeLineProps } from './types';

// MUI
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

// Misc
import { convertCentsToDollar } from 'utils/orders';
import { Heading } from '../Heading';

const TimeLine: React.FC<TimeLineProps> = ({ timeLineData }) => {
  return (
    <Timeline className="lumanti-time_line" position="alternate">
      {timeLineData.map((data, index) => {
        return (
          <TimelineItem key={`${data.name}${String(index)}`}>
            <TimelineOppositeContent color="text.secondary" sx={{ m: 'auto 0' }} variant="body2">
              <Heading align="center" className="lumanti-time_line-date" size={16} type="h4">
                {data.date} {data?.time}
              </Heading>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <Avatar type="icon" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Heading align="center" size={16} type="h4">
                {data.name ? data.name : data.phoneNumber}
              </Heading>
              <Paragraph align="center" className="lumanti-time_line-amount">
                ${convertCentsToDollar(data.totalAmount)}
              </Paragraph>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export { TimeLine };
