// Globals
import React from 'react';

// Components

// Services
import { doSignOut } from '../../../services/auth/async';

// Mui
import DashboardIcon from '@mui/icons-material/Dashboard';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import Toolbar from '@mui/material/Toolbar';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

// Misc
import { LmtDispatch } from 'store';
import { toLower } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Component
const SideNavBarDrawer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: LmtDispatch = useDispatch();

  const getIconComponent = (icon: string) => {
    switch (icon) {
      case 'Dashboard':
        return <DashboardIcon style={{ color: '#adceff' }} />;
      case 'Orders':
        return <ReceiptIcon style={{ color: '#adceff' }} />;
      case 'Expenses':
        return <RequestQuoteIcon style={{ color: '#adceff' }} />;
      case 'Reports':
        return <TrendingUpIcon style={{ color: '#adceff' }} />;
      case 'Menu':
        return <RestaurantMenuIcon style={{ color: '#adceff' }} />;
      default:
        return null;
    }
  };

  // Handler
  const handleClick = (text: string) => {
    const url = text === 'Dashboard' ? '/' : `/${toLower(text)}`;
    navigate(url);
  };

  return (
    <div>
      <Toolbar />
      <Divider style={{ borderColor: '#adceff' }} />
      <List
        sx={{
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#2551b8',
            '&, & .MuiListItemIcon-root': {
              color: '#f7e5ff'
            }
          }
        }}
      >
        {['Dashboard', 'Orders', 'Expenses', 'Reports', 'Menu'].map((text, index) => (
          <ListItem disablePadding key={text}>
            <ListItemButton onClick={() => handleClick(text)}>
              <ListItemIcon>{getIconComponent(text)}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider style={{ borderColor: '#adceff' }} />
      <List
        sx={{
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#2551b8',
            '&, & .MuiListItemIcon-root': {
              color: '#f7e5ff'
            }
          }
        }}
      >
        <ListItem disablePadding>
          <ListItemButton onClick={() => dispatch<any>(doSignOut())}>
            <ListItemIcon>
              <LogoutIcon style={{ color: '#adceff' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export { SideNavBarDrawer };
