// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Modal } from 'components/Modal';

// Misc
import { capitalize } from 'lodash';
import { convertCentsToDollar, formatCurrency } from 'utils/orders';
import { ModalPLBreakdownProps } from './types';
import { ModalBodyText } from '../../components/Modal/BodyText';

const ModalPLBreakdown: React.FC<ModalPLBreakdownProps> = ({
  hide,
  isVisible,
  profitOrLoss,
  taxesAndFeesTotal,
  transactionsExpenses,
  transactionsExpensesReimmbursement,
  transactionsExpensesTotal,
  transactionsRevenue,
  transactionsRevenueTaxAndDelivery,
  transactionsRevenueTotal
}) => {
  return (
    <Modal className="lumanti-reports-profit-loss-card" hide={hide} isVisible={isVisible}>
      <ModalBodyText>
        <div className="lumanti-reports-report">
          <Heading align="center" className="lumanti-reports-heading" size={18} type="h3">
            REVENUE
          </Heading>
          <div className="lumanti-reports-container">
            {transactionsRevenue.map((transaction, index) => {
              return (
                <div className="lumanti-reports-content" key={`${transaction?.name}${index}`}>
                  <Heading size={16} type="h4">
                    {capitalize(transaction?.name)}
                  </Heading>
                  <Paragraph>${convertCentsToDollar(transaction?.value as string)}</Paragraph>
                </div>
              );
            })}
          </div>

          <div className="lumanti-reports-content-total">
            <Heading size={18} type="h4">
              Total
            </Heading>
            <Heading align="right" size={18} type="h4">
              ${convertCentsToDollar(transactionsRevenueTotal.toString())}
            </Heading>
          </div>

          <Heading align="center" className="lumanti-reports-heading" size={18} type="h3">
            EXPENSES
          </Heading>

          <div className="lumanti-reports-container">
            {transactionsExpenses.map((exp, index) => {
              return (
                <div className="lumanti-reports-content" key={`${exp?.name}${index}`}>
                  <Heading size={16} type="h4">
                    {capitalize(exp?.name)}
                  </Heading>
                  <Paragraph>${formatCurrency(exp?.value as string)}</Paragraph>
                </div>
              );
            })}
          </div>

          <div className="lumanti-reports-content-total">
            <Heading size={18} type="h4">
              Total
            </Heading>
            <Heading align="right" size={18} type="h4">
              ${formatCurrency(transactionsExpensesTotal.toString())}
            </Heading>
          </div>

          <Heading align="center" className="lumanti-reports-heading" size={18} type="h3">
            TAXES AND FEES
          </Heading>

          <div className="lumanti-reports-content">
            <Heading size={16} type="h4">
              Tax Collected
            </Heading>
            <Paragraph>
              ${convertCentsToDollar(transactionsRevenueTaxAndDelivery.taxAmount.toString())}
            </Paragraph>
          </div>

          <div className="lumanti-reports-content">
            <Heading size={16} type="h4">
              Delivery Fee Collected
            </Heading>
            <Paragraph>${transactionsRevenueTaxAndDelivery.delivery}</Paragraph>
          </div>

          <div className="lumanti-reports-content-total">
            <Heading size={18} type="h4">
              Total
            </Heading>
            <Heading align="right" size={18} type="h4">
              ${taxesAndFeesTotal}
            </Heading>
          </div>

          <Heading align="center" className="lumanti-reports-heading" size={18} type="h3">
            REIMBURSEMENT
          </Heading>

          <div className="lumanti-reports-content">
            <Heading size={16} type="h4">
              Reimbursement
            </Heading>
            <Paragraph>${formatCurrency(transactionsExpensesReimmbursement?.personal)}</Paragraph>
          </div>

          <div className="lumanti-reports-content-total">
            <Heading size={18} type="h4">
              Profit
            </Heading>
            <Heading align="right" size={18} type="h4">
              {profitOrLoss}
            </Heading>
          </div>
        </div>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalPLBreakdown };
