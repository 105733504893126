// Globals
import React, { useRef, FC } from 'react';
import { useLink } from 'react-aria';
import './styles.scss';

// Components
import { Icon } from 'components/Icon';

// Types
import { AriaLinkProps, KeyDownData } from './types';
import type { AriaLinkOptions } from '@react-aria/link';
import type { PressEvent, KeyboardEvent } from '@react-types/shared';

// Misc
import clsx from 'clsx';
import { getWindow } from 'ssr-window';
import { useNavigate } from 'react-router-dom';

// Component
const AriaLink: FC<AriaLinkProps> = ({
  className,
  display,
  icon,
  intent = 'none',
  isDisabled,
  isFullWidth,
  keyDownData = [],
  trackingDisabled,
  onClick,
  isNewTab = false,
  path,
  href,
  children,
  ...rest
}) => {
  // PropType error
  if (href && path) throw new Error('AriaLink cannot use both href and path, choose one');

  // Hooks
  const ref = useRef(null);
  const navigate = useNavigate();
  const window = getWindow();
  const { linkProps } = useLink(
    {
      onPress: (event: PressEvent) => handleOnClick(event),
      elementType: 'div',
      isDisabled,
      ...rest
    } as AriaLinkOptions,
    ref
  );

  const handleOnClick = (event: PressEvent) => {
    if (isDisabled) return;

    // Optional onClick
    if (onClick) {
      onClick(event);
    }

    // Relative path
    if (path) {
      navigate(path);
    } else if (href && isNewTab) {
      // Absolute path
      window.open(href, '_blank');
    } else if (href && !isNewTab) {
      window.open(href, '_self');
    }
  };

  // Vars
  const classes = clsx('lumanti-aria_link', className, {
    'lumanti-aria_link-button': intent !== 'none',
    'lumanti-aria_link-primary': intent === 'primary',
    'lumanti-aria_link-secondary': intent === 'secondary',
    'lumanti-aria_link-text': intent === 'text',
    'lumanti-aria_link-inline': display === 'inline',
    'lumanti-aria_link-disabled': isDisabled,
    'lumanti-aria_link-full-width': isFullWidth
  });

  // Render
  return (
    <div {...linkProps} className={classes} ref={ref}>
      {Boolean(icon) && <Icon className="lumanti-aria_link-icon" name={icon} />}
      {children}
    </div>
  );
};

export { AriaLink };
