import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Paragraph } from '../Paragraph';

// Misc
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  className?: string;
  label: string;
  value: string;
  navigateTo?: string;
}

// Component
const CardExpense: React.FC<CardProps> = ({ className = '', label, value, navigateTo = '' }) => {
  const navigate = useNavigate();

  const classes = clsx('lumanti-card_expenses', className);

  return (
    <div className={classes} onClick={() => navigate(`/${navigateTo}`)}>
      <img
        alt="lumanti"
        className="lumanti-card_expenses-icon"
        src={require('assets/media/illustration/ic_expenses.png')}
      />
      <Heading className="lumanti-card_expenses-value" size={32} type="h2">
        ${value}
      </Heading>
      <Paragraph className="lumanti-card_expenses-label" size={14}>
        {label}
      </Paragraph>
    </div>
  );
};

export { CardExpense };
