/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Globals
import './styles.scss';
import React, { useEffect } from 'react';

// Components
import { Icon } from 'components/Icon';
import { Portal } from 'components/Portal';
import { Transition } from 'react-transition-group';

// Types
import { ModalProps } from './types';

// Misc
import clsx from 'clsx';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';

// Component
function Modal(props: ModalProps) {
  const {
    className = '',
    children,
    hide,
    isClosable = true,
    isSuccess = false,
    isVisible = false
  } = props;

  // Handle effect for hiding modal
  useEffectDidUpdate(() => {
    if (isSuccess) {
      hide();
    }
  }, [isSuccess]);

  // Handle effect for classList
  useEffect(() => {
    const classList = document.querySelector('html')?.classList!;
    const lockClass = 'lumanti-modal-lock-scroll';

    // Prevent scroll behind modal when open
    if (isVisible) {
      classList.add(lockClass);
    } else {
      classList.remove(lockClass);
    }

    return () => {
      classList.remove(lockClass);
    };
  }, [isVisible]);

  const stopClickBubble = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
  };

  const closeHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    hide();
  };

  // Vars
  const transitionProps = {
    in: isVisible,
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: 100
  };

  // Render
  return (
    <Transition {...transitionProps}>
      {(state) => {
        const classes = clsx('lumanti-modal', className, {
          // Transitions
          'lumanti-modal-entering': state === 'entering',
          'lumanti-modal-entered': state === 'entered',
          'lumanti-modal-exiting': state === 'exiting',
          'lumanti-modal-exited': state === 'exited'
        });

        return (
          <Portal className={classes}>
            <div
              aria-modal="true"
              className="lumanti-modal-backdrop"
              onClick={stopClickBubble}
              onKeyDown={stopClickBubble}
              role="dialog"
            >
              <div className="lumanti-modal-container">
                {/* Icon */}
                {isClosable && (
                  <div
                    className="lumanti-modal-icon-container"
                    onClick={closeHandler}
                    role="button"
                    tabIndex={0}
                  >
                    <Icon className="lumanti-modal-icon" name="close" />
                  </div>
                )}

                {/* Body */}
                {children}
              </div>
            </div>
          </Portal>
        );
      }}
    </Transition>
  );
}

export { Modal };
