import { combineReducers } from '@reduxjs/toolkit';
import { AllAuthActions, AuthActionTypes, AuthState, UserDetails } from './types';
import { UserCredential } from 'firebase/auth';

export const setUser = (data: UserCredential) => ({
  type: AuthActionTypes.SET_USER_TOKEN,
  data
});

export const setError = (data: any) => ({
  type: AuthActionTypes.SET_ERROR,
  data
});

export const clearToken = () => ({
  type: AuthActionTypes.CLEAR_USER_TOKEN
});

export const clearError = () => ({
  type: AuthActionTypes.CLEAR_ERROR
});

const initialUserDetails = {
  displayName: '',
  email: '',
  photoUrl: '',
  uid: ''
};

// Sub-reducers
export function accessToken(accessToken = '', action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_USER_TOKEN: {
      return action.data?.user.accessToken;
    }
    case AuthActionTypes.CLEAR_USER_TOKEN: {
      return '';
    }
    default:
      return accessToken;
  }
}

// export function refreshToken(refreshToken = '', action: AllAuthActions) {
//   switch (action.type) {
//     case AuthActionTypes.SET_USER_TOKEN: {
//       return action.data?.user?.;
//     }
//   }
// }
//
// export function expirationTime(expirationTime = '', action: AllAuthActions) {
//   switch (action.type) {
//     case AuthActionTypes.SET_USER_TOKEN: {
//       return action.data?.user?.stsTokenManager.expirationTime;
//     }
//   }
// }

export function userDetails(userDetails: UserDetails = initialUserDetails, action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_USER_TOKEN: {
      const user = action.data.user;
      return {
        displayName: user.displayName,
        email: user.email,
        photoUrl: user.photoUrl,
        uid: user.uid
      };
    }
    case AuthActionTypes.CLEAR_USER_TOKEN:
      return initialUserDetails;
    default:
      return userDetails;
  }
}

export function authError(error = '', action: AllAuthActions) {
  switch (action.type) {
    case AuthActionTypes.SET_ERROR: {
      return action.data.code;
    }
    case AuthActionTypes.CLEAR_ERROR:
      return '';
    default:
      return error;
  }
}

// Root Reducer
export const auth = combineReducers<AuthState>({
  accessToken,
  // refreshToken,
  // expirationTime,
  userDetails,
  authError
});
