import './styles.scss';
import React from 'react';

// Components
import { Card } from 'components/Card';
import { Heading } from 'components/Heading';

// Services
import { getOrdersFilter, getOrdersListPaidTotal } from 'services/orders/selectors';
import { setFilter } from 'services/orders/reducers';

// Misc
import clsx from 'clsx';
import { capitalize, snakeCase } from 'lodash';
import { convertCentsToDollar } from 'utils/orders';
import { LmtDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { MoreMenu } from '../MoreMenu';
import { Paragraph } from '../Paragraph';

interface CardTransactionsProps {
  className?: string;
}

// Component
const CardTransactions: React.FC<CardTransactionsProps> = ({ className = '' }) => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  const paid = useSelector(getOrdersListPaidTotal);
  const filter = useSelector(getOrdersFilter);

  const classes = clsx('lumanti-card_transactions', className);

  const options = [
    { label: 'This week', cta: () => dispatch(setFilter('week')) },
    { label: 'This month', cta: () => dispatch(setFilter('month')) },
    { label: 'This year', cta: () => dispatch(setFilter('all')) }
  ];

  const filterLabel = () => {
    switch (filter) {
      case 'week':
        return 'This week';
      case 'month':
        return 'This month';
      default:
        return 'Year to date';
    }
  };

  return (
    <Card
      className={classes}
      moreOption={<MoreMenu options={options} />}
      title={`Transactions - ${filterLabel()}`}
    >
      {Object.keys(paid).length === 0 && (
        <div className="lumanti-card_transactions-no-transaction">
          <img
            alt="person in yellow"
            src={require('assets/media/illustration/woman-illustration.png')}
            width={250}
          />
          <Heading size={16} type="h4">
            No Transactions Yet
          </Heading>
        </div>
      )}

      {Object.keys(paid).length > 0 &&
        Object.keys(paid).map((payment) => {
          return (
            <div className="lumanti-card_transactions-transaction" key={payment}>
              <div className="lumanti-card_transactions-transaction-type">
                <img
                  alt={`${payment}`}
                  src={require(`assets/media/payment/${snakeCase(payment)}.png`)}
                  width={48}
                />
                <Heading size={18} type="h4">
                  {capitalize(payment)}
                </Heading>
              </div>

              <Heading size={18} type="h3">
                ${convertCentsToDollar(paid[payment])}
              </Heading>
            </div>
          );
        })}

      {/*{paid.length === 0 && 'No Transactions Yet'}*/}
    </Card>
  );
};

export { CardTransactions };
