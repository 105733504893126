// Globals
import React from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import { InputSelectOptionProps } from './types';

const InputSelectOption: React.FC<InputSelectOptionProps> = ({
  children,
  disabled,
  label,
  value,
  selectOption,
  selectedLabel,
  tabIndex
}) => {
  // Handlers
  const handleSelectOption = (event: React.MouseEvent<HTMLDivElement>) => {
    // @ts-ignore
    return !disabled && selectOption && selectOption(label, value, event);
  };

  // Render
  return (
    <li>
      <div
        className="lumanti-input_select-list-item"
        data-label={selectedLabel}
        onClick={handleSelectOption}
        role="button"
        tabIndex={tabIndex}
        // @ts-ignore
        value={value}
      >
        {children}
        {!disabled && label === selectedLabel && (
          <Icon className="lumanti-input_select-list-item-icon" name="checkmark" title="check" />
        )}
      </div>
    </li>
  );
};

export { InputSelectOption };
