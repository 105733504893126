import './styles.scss';
import * as React from 'react';

// Components
import { Heading } from 'components/Heading';
import { SideNavBar } from '../SideNavBar';

// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { AriaLink } from '../AriaLink';

const drawerWidth = 240;

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, className = '' }) => {
  // Hooks
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="lumanti-layout">
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar style={{ backgroundColor: '#121e2d', color: '#d6e6ff' }}>
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <AriaLink path={'/'}>
              <img
                alt="yellow lumanti"
                className="lumanti-layout-logo"
                src={require('assets/media/lumanti-logo-text.png')}
              />
            </AriaLink>
          </Toolbar>
        </AppBar>

        <SideNavBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <div className={className}>{children}</div>
        </Box>
      </Box>
    </div>
  );
};

export { Layout };
