// Globals
import React from 'react';

// Components
import { ButtonIcon } from './Icon';
import { ButtonText } from './Text';

// Types
import { ButtonProps, ButtonType } from './types';

// Misc
import { useNavigate } from 'react-router-dom';

// Constants
const ButtonTypes = {
  icon: ButtonIcon,
  submit: ButtonText,
  button: ButtonText
};

// Component
const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  goto,
  hoverText,
  icon,
  isDisabled,
  onClick,
  type = ButtonType.BUTTON,
  isFullWidth,
  ...passedProps
}) => {
  // Hooks
  const navigate = useNavigate();

  // Handlers
  const click = (event: React.MouseEvent<HTMLElement>) => {
    // Click event for submit is handled in Form Component
    // Optional handler
    if (onClick) {
      onClick(event);
    }

    // Update the router
    if (goto) {
      navigate(goto);
    }
  };

  // Vars
  const Component = ButtonTypes[type];
  // eslint-disable-next-line no-negated-condition
  const widthProp = Component !== ButtonTypes.icon ? { isFullWidth } : {};

  const buttonProps = {
    children,
    className,
    disabled: isDisabled,
    hoverText,
    icon: icon ?? '',
    onClick: click,
    type,
    ...passedProps,
    ...widthProp
  };

  // Render
  return <Component {...buttonProps} />;
};

export { Button };
