// Globals
import './styles.scss';
import React from 'react';

// Misc
import clsx from 'clsx';

// Types
import { ParagraphProps } from './types';

// Component
const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { align, className, color, size = 16, ellipsis, children } = props;

  const classes = clsx('lumanti-paragraph', className, {
    'lumanti-paragraph-center': align === 'center',
    'lumanti-paragraph-right': align === 'right',
    'lumanti-paragraph-white': color === 'white',
    'lumanti-paragraph-22': size === 22,
    'lumanti-paragraph-18': size === 18,
    'lumanti-paragraph-16': size === 16,
    'lumanti-paragraph-14': size === 14,
    'lumanti-paragraph-12': size === 12,
    'lumanti-paragraph-ellipsis': ellipsis
  });

  const domProps = { className: classes, children };
  return <p {...domProps} />;
};

export { Paragraph };
