// Globals
import React from 'react';

// Components
import { Card } from 'components/Card';
import { TimeLine } from 'components/TimeLine';

// Services
import { getOrdersListTimeLine } from 'services/orders/selectors';

// Misc
import { useSelector } from 'react-redux';

// Component
const HomeCardTimeLine: React.FC = () => {
  // Hooks - selector
  const timelineData = useSelector(getOrdersListTimeLine);

  return (
    <Card title="Upcoming Orders">
      <TimeLine timeLineData={timelineData} />
    </Card>
  );
};

export { HomeCardTimeLine };
