export interface ModalAddOrderV2Props {
  hide: () => void;
  isVisible: boolean;
}

export interface OrderInfo {
  serviceDate: string;
  serviceTime: string;
  name: string;
  address: string;
  phoneNumber: string;
}

export const orderInfoInitial = {
  serviceDate: '',
  serviceTime: '',
  name: '',
  address: '',
  phoneNumber: ''
};

export interface OrderMisc {
  discount: string;
  tax: string;
  delivery: string;
}

export const orderMiscInitial = {
  discount: '',
  tax: '',
  delivery: ''
};

export const orderDetailsInitial = [{ itemName: '', itemSize: '', itemQty: '', itemPrice: '' }];

export interface OrderDetails {
  itemName: string;
  itemSize: string;
  itemQty: string;
  itemPrice: string;
}

export interface PaymentInfo {
  deposit: string;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
}

export enum PaymentMethod {
  ZELLE = 'zelle',
  VENMO = 'venmo',
  CASH = 'cash',
  CREDIT_CARD = 'creditCard',
  SQUARE = 'square',
  NA = ''
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export const paymentInfoInitial = {
  deposit: '',
  paymentStatus: PaymentStatus.UNPAID,
  paymentMethod: PaymentMethod.NA
};

export const paymentMethodOptions = [
  { label: 'Zelle', value: PaymentMethod.ZELLE },
  { label: 'Venmo', value: PaymentMethod.VENMO },
  { label: 'Cash', value: PaymentMethod.CASH },
  { label: 'Credit Card', value: PaymentMethod.CREDIT_CARD },
  { label: 'Square', value: PaymentMethod.SQUARE },
  { label: 'Select Payment Method', value: PaymentMethod.NA }
];

export const paymentStatusOptions = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' }
];
