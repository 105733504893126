// Globals
import './styles.scss';
import React from 'react';

// Components
import { Icon } from 'components/Icon';

// Misc
import AccordionContext from './context';
import clsx from 'clsx';

// Types
import { AccordionProps } from './types';
import { MoreMenu } from '../MoreMenu';

// Component
const Accordion: React.FunctionComponent<AccordionProps> = ({
  children,
  className,
  isDisabled = false,
  isOpen = false,
  moreMenuOptions = [],
  onClick = () => null,
  topBar = true
}) => {
  // Hooks
  const [isToggleOpen, setIsToggleOpen] = React.useState(isOpen);

  // Hooks - effects
  React.useEffect(() => {
    setIsToggleOpen(isOpen);
  }, [isOpen]);

  // Handlers
  const toggleIsOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (isDisabled) {
      return;
    }

    setIsToggleOpen((prevIsOpen) => !prevIsOpen);

    if (onClick) {
      onClick(event);
    }
  };

  // Vars
  const classes = clsx('lumanti-accordion', className, {
    'lumanti-accordion-disabled': isDisabled,
    'lumanti-accordion-active': isToggleOpen
  });

  // Render
  return (
    <AccordionContext.Provider value={{ isToggleOpen }}>
      <div className={classes}>
        <div className="lumanti-accordion-top-bar-container">
          <button
            aria-expanded={isToggleOpen}
            className="lumanti-accordion-heading-container"
            data-element-type="link-text"
            disabled={isDisabled}
            onClick={toggleIsOpen}
            type="button"
          >
            <div className="lumanti-accordion-top-bar">{topBar}</div>
            <div className="lumanti-accordion-arrow-background">
              <Icon name={isToggleOpen ? 'arrow_up' : 'arrow_down'} />
            </div>
          </button>
          {moreMenuOptions?.length > 0 && <MoreMenu options={moreMenuOptions} />}
        </div>

        {isToggleOpen && <div aria-hidden={!isToggleOpen}>{children}</div>}
      </div>
    </AccordionContext.Provider>
  );
};

export { Accordion };
