import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from 'config/firebase';
import { LmtThunk } from 'store/types';
import { setOrders } from './reducers';
import { setLoading } from '../api/reducers';

const ordersCollectionRef = collection(db, 'order');

export function doGetOrders(): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const resp = await getDocs(ordersCollectionRef);
      const filteredData = resp.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      dispatch(setOrders(filteredData));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function doAddOrder(data: any): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await addDoc(ordersCollectionRef, data);
      await dispatch(doGetOrders());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function doDeleteOrder(id: string): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const orderDoc = doc(db, 'order', id);
      await deleteDoc(orderDoc);
      await dispatch(doGetOrders());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function doUpdateOrder(id: string, data: any): LmtThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const orderDoc = doc(db, 'order', id);
      await updateDoc(orderDoc, data);
      await dispatch(doGetOrders());
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}
