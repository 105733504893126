export const calculateSumsGroup = (data: any, groupBy: string) => {
  const sums: any = {};

  Object.keys(data).forEach((group) => {
    const amounts = data[group].map((item: any) => item[groupBy]);
    sums[group] = amounts.reduce(
      (acc: any, curr: any) => (Number(acc) + Number(curr)).toString(),
      0
    );
  });

  return sums;
};

export const calculateTotal = (data: any) => {
  let sum = 0;

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      sum += parseFloat(data[key]);
    }
  }

  return sum;
};
