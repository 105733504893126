export interface ModalAddOrderV2Props {
  hide: () => void;
  isVisible: boolean;
}

export interface ModalUpdateOrderProps {
  hide: () => void;
  isVisible: boolean;
  updateOrderDetails: OrderDetails[];
  updateOrderInfo: OrderInfo;
  updateOrderMisc: OrderMisc;
  updatePaymentInfo: PaymentInfo;
  orderId: string;
}

export interface OrderInfo {
  serviceDate: string;
  serviceTime: string;
  name: string;
  address: string;
  phoneNumber: string;
}

export const orderInfoInitial = {
  serviceDate: '',
  serviceTime: '',
  name: '',
  address: '',
  phoneNumber: ''
};

export interface OrderMisc {
  discount: string;
  tax: string;
  delivery: string;
}

export const orderMiscInitial = {
  discount: '',
  tax: '',
  delivery: ''
};

export const orderDetailsInitial = [{ itemName: '', itemSize: '', itemQty: '', itemPrice: '' }];

export interface OrderDetails {
  itemName: string;
  itemSize: string;
  itemQty: string;
  itemPrice: string;
}

export interface PaymentInfo {
  deposit: string;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
}

export enum PaymentMethod {
  ZELLE = 'zelle',
  VENMO = 'venmo',
  CASH = 'cash',
  CREDIT_CARD = 'creditCard',
  SQUARE = 'square',
  CHECK = 'check',
  PAYPAL = 'paypal',
  NA = ''
}

export enum PaymentStatus {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export const paymentInfoInitial = {
  deposit: '',
  paymentStatus: PaymentStatus.UNPAID,
  paymentMethod: PaymentMethod.NA
};

export const paymentMethodOptions = [
  { label: 'Zelle', value: PaymentMethod.ZELLE },
  { label: 'Venmo', value: PaymentMethod.VENMO },
  { label: 'Cash', value: PaymentMethod.CASH },
  { label: 'Credit Card', value: PaymentMethod.CREDIT_CARD },
  { label: 'Square', value: PaymentMethod.SQUARE },
  { label: 'Select Payment Method', value: PaymentMethod.NA }
];

export const paymentStatusOptions = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' }
];

export const textFieldClasses = {
  marginBottom: '20px',
  '& input': { color: '#d6e6ff' },
  '& .MuiTextField-root': {
    '& fieldset': { borderColor: '#d6e6ff' },
    '&:hover fieldset': { borderColor: '#d6e6ff' },
    '&.Mui-focused fieldset': { borderColor: '#d6e6ff' }
  },
  '& label.Mui-focused': { color: '#d6e6ff' },
  '& .MuiInputLabel-root': { color: '#d6e6ff' },
  '& .MuiInput-underline:after': { borderBottomColor: 'white' },
  '& .MuiToggleButton-root': {
    color: '#d6e6ff',
    borderColor: '#d6e6ff',
    '&.Mui-selected': { color: '#1d2f50', backgroundColor: '#6694ff' }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#d6e6ff' },
    '&:hover fieldset': { borderColor: '#d6e6ff' },
    '&.Mui-focused fieldset': { borderColor: '#d6e6ff' },
    '& .MuiTypography-root': { color: '#d6e6ff' }
  }
};

export const stepperClasses = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#fafcff' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: '#fafcff' // Just text label (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: '#36f' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-disabled': {
    color: '#cfd5e3' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text': {
    fill: '#fafcff' // circle's number (ACTIVE)
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: '#fafcff' // Just text label (ACTIVE)
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: '#fafcff' // circle's number (ACTIVE)
  }
};
