import { AllApiActions, ApiActionTypes, ApiState } from './types';
import { combineReducers } from '@reduxjs/toolkit';

export const setLoading = (data: boolean) => ({
  type: ApiActionTypes.SET_LOADING,
  data
});

// Sub-reducers
export function isLoading(isLoading = false, action: AllApiActions) {
  switch (action.type) {
    case ApiActionTypes.SET_LOADING:
      return action.data;
    default:
      return isLoading;
  }
}

// Root Reducer
export const api = combineReducers<ApiState>({
  isLoading
});
