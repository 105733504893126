// Globals
import './styles.scss';
import React from 'react';

// Components
import { Icon } from 'components/Icon';
import NumberFormat from 'react-number-format';

// Misc
import { InputNumberProps } from './types';
import clsx from 'clsx';

// Component
const InputNumber: React.FC<InputNumberProps> = (props) => {
  // Props
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputLabel, // Destructure from being passed to input element
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange, // Destructure unnecessary handlers
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInput, // Destructure unnecessary handlers
    icon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pattern,
    ...restProps
  } = props;

  // Vars
  const classes = clsx({
    'lumanti-input_number': true,
    'lumanti-input_number-has-icon': icon
  });
  const iconClasses = clsx({
    'lumanti-input_number-icon': true,
    'lumanti-input_number-icon-empty': !restProps.value
  });

  // Render
  return (
    <div className={classes}>
      <NumberFormat allowLeadingZeros type="tel" {...restProps} />

      {/* Optional icon */}
      {icon && <Icon className={iconClasses} name={icon} />}
    </div>
  );
};

export { InputNumber };
