import * as React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalBodyText } from 'components/Modal/BodyText';

// Services
import { doAddExpense } from 'services/expense/async';

// MUI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Misc
import dayjs from 'dayjs';
import { ExpenseInfo, initialExpenseInfo } from './types';
import { LmtDispatch } from 'store';
import { stepperClasses, textFieldClasses } from 'utils/MuiClasses';
import { useDispatch } from 'react-redux';

export interface ModalAddExpenseProps {
  hide: () => void;
  isVisible: boolean;
}

export const categoryOptions = [
  { label: 'Fuel', value: 'fuel' },
  { label: 'Grocery', value: 'grocery' },
  { label: 'Maintaince', value: 'maintainance' },
  { label: 'Meal', value: 'meal' },
  { label: 'Misc', value: 'misc' },
  { label: 'None', value: '' },
  { label: 'Rent', value: 'Rent' },
  { label: 'Rental', value: 'Rental' },
  { label: 'Sel Roti', value: 'sel' },
  { label: 'Travel', value: 'travel' }
];

export const cardOwnerOptions = [
  { label: 'Business - xxxx9895', value: 'business_chase_9595' },
  { label: 'Business - xxxx5096', value: 'business_boa_5096' },
  { label: 'Personal', value: 'personal' }
];

const steps = [{ label: 'Expenses', description: '' }];

const ModalAddExpense: React.FC<ModalAddExpenseProps> = ({ hide, isVisible }) => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  // Hooks
  const [activeStep, setActiveStep] = React.useState(0);
  const [expenseInfo, setExpenseInfo] = React.useState(initialExpenseInfo);
  const [category, setCategory] = React.useState('');
  const [cardOwner, setCardOwner] = React.useState('');

  React.useEffect(() => {
    formReset();
    setActiveStep(0);
  }, [isVisible]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setCategory(value);
    setExpenseInfo({ ...expenseInfo, category: value });
  };

  const handleCardChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setCardOwner(value);
    setExpenseInfo({ ...expenseInfo, cardOwner: value });
  };

  const handleOnChange = (value: string, field: keyof ExpenseInfo) => {
    setExpenseInfo({ ...expenseInfo, [field]: value });
  };

  const formReset = () => {
    setExpenseInfo(initialExpenseInfo);
    setCategory('');
    setCardOwner('');
  };

  const onFormSubmit = () => {
    const data = { ...expenseInfo, receiptDate: expenseInfo.receiptDate?.format('LL') };
    dispatch<any>(doAddExpense(data));
    hide();
  };

  return (
    <Modal className="lumanti-modal_add_expense" hide={hide} isVisible={isVisible}>
      <ModalBodyText>
        <div className="lumanti-modal_add_expense-body">
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} sx={stepperClasses}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  {index === 0 && (
                    <Box sx={textFieldClasses}>
                      <div className="lumanti-modal_add_expense-info">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Receipt Date"
                            onChange={(newValue) =>
                              setExpenseInfo({ ...expenseInfo, receiptDate: dayjs(newValue) })
                            }
                            slotProps={{ textField: { size: 'medium' } }}
                            value={expenseInfo.receiptDate as any}
                          />
                        </LocalizationProvider>

                        <FormControl fullWidth>
                          <InputLabel id="category-select-label">Category</InputLabel>
                          <Select
                            id="category-select"
                            label="Category"
                            labelId="category"
                            onChange={handleCategoryChange}
                            value={category as any}
                          >
                            {categoryOptions.map((option) => {
                              return (
                                <MenuItem key={option.label} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <TextField
                          fullWidth
                          id="outlined-required"
                          label="Mechant"
                          name="merchant"
                          onChange={(e) =>
                            handleOnChange((e.target as HTMLInputElement).value, 'merchant')
                          }
                          value={expenseInfo.merchant}
                        />

                        <FormControl fullWidth sx={{ m: 1, margin: '8px 0' }}>
                          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            label="Amount"
                            name="amount"
                            onChange={(e) =>
                              handleOnChange((e.target as HTMLInputElement).value, 'amount')
                            }
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            value={expenseInfo.amount}
                          />
                        </FormControl>

                        <FormControl fullWidth>
                          <InputLabel id="category-select-label">Card Owner</InputLabel>
                          <Select
                            id="card-owner-select"
                            label="Card Owner"
                            labelId="cardOwner"
                            onChange={handleCardChange}
                            value={cardOwner as any}
                          >
                            {cardOwnerOptions.map((option) => {
                              return (
                                <MenuItem key={option.label} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Box>
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div className="lumanti-modal_add_expense-nav-btns">
                      <Button intent="primary" onClick={onFormSubmit}>
                        Add Expense
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalAddExpense };
