import { AllOrdersAction, OrderList, OrdersActionTypes, OrdersState } from './types';
import { combineReducers } from '@reduxjs/toolkit';

export const setOrders = (data: any) => ({
  type: OrdersActionTypes.SET_ORDERS,
  data
});

export const setFilter = (data: string) => ({
  type: OrdersActionTypes.SET_FILTER,
  data
});

// Sub-reducers
export function list(list: OrderList[] = [], action: AllOrdersAction) {
  switch (action.type) {
    case OrdersActionTypes.SET_ORDERS: {
      return action.data;
    }
    default:
      return list;
  }
}

export function filter(filter: string = 'all', action: AllOrdersAction) {
  switch (action.type) {
    case OrdersActionTypes.SET_FILTER:
      return action.data;
    default:
      return filter;
  }
}

// Root Reducer
export const orders = combineReducers<OrdersState>({
  list,
  filter
});
