import * as React from 'react';

// Components
import { Heading } from 'components/Heading';

// MUI
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Types
import { MoreMenuOptions } from 'components/Accordion/types';

interface MoreMenuProps {
  options: MoreMenuOptions[];
}

const ITEM_HEIGHT = 48;

const MoreMenu: React.FC<MoreMenuProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<undefined | null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (handleClick?: () => void) => {
    if (handleClick) {
      handleClick();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" id="long-button" onClick={handleClick}>
        <MoreVertIcon sx={{ color: '#d6e6ff' }} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            backgroundColor: '#121e2d',
            color: '#d6e6ff'
          }
        }}
        anchorEl={anchorEl}
        id="long-menu"
        onClose={() => handleClose()}
        open={open}
      >
        {options.map((option) => (
          <MenuItem key={option.label} onClick={() => handleClose(option.cta)}>
            <Heading size={16} type="h4">
              {option.label}
            </Heading>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export { MoreMenu };
