// Action types
export enum OrdersActionTypes {
  SET_ORDERS = 'lumanti.orders.SET_ORDERS',
  SET_FILTER = 'lumanti.orders.SET_FILTER'
}

// Generic Orders actions
export interface OrdersAction<P> {
  type: OrdersActionTypes;
  data: P;
}

export interface OrdersSetOrdersAction extends OrdersAction<any> {
  type: OrdersActionTypes.SET_ORDERS;
}

export interface OrdersSetOrdersFilter extends OrdersAction<any> {
  type: OrdersActionTypes.SET_FILTER;
}

export type AllOrdersAction = OrdersSetOrdersAction | OrdersSetOrdersFilter;

export interface OrdersState {
  list: OrderList[];
  filter: string;
}

export interface OrdersDetail {
  itemName: string;
  itemPrice: string;
  itemQty: string;
  itemSize: string;
}

export interface Order {
  address: string;
  delivery: string;
  discount: string;
  discountAmount: string;
  name: string;
  orderDetails: OrdersDetail[];
  orderNumber: string;
  paymentMethod: string;
  phoneNumber: string;
  serviceDate: string;
  subTotal: string;
  taxAmount: string;
  totalAmount: string;
}

export interface OrderList {
  address: string;
  delivery: string;
  deposit: string;
  discount: string;
  discountAmount: string;
  id: string;
  name: string;
  orderDetails: OrdersDetail[];
  orderNumber: string;
  paymentMethod: string;
  paymentStatus: string;
  phoneNumber: string;
  serviceDate: string;
  subTotal: string;
  taxAmount: string;
  totalAmount: string;
}
