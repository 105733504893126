import './styles.scss';
import React from 'react';

// Components
import { CardTransactions } from 'components/CardTransaction';
import { HomeCardTimeLine } from '../CardTimeLine';

const HomeOrdersTransaction: React.FC = () => {
  // React - useEffect
  React.useEffect(() => {}, []);

  return (
    <div className="lumanti-home_orders_transactions">
      <CardTransactions />
      <HomeCardTimeLine />
    </div>
  );
};

export { HomeOrdersTransaction };
