// Globals
import './styles.scss';
import React from 'react';

// Misc
import { removeProps } from 'utils/removeProps';
import { InputCheckboxProps } from './types';

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const {
    children,
    'data-value': dataValue = '',
    checked,
    defaultChecked,
    value,
    ...otherProps
  } = props;

  /* 
    Pick which value to use for checked state, prioritizing to defaultChecked.
    Do not allow both at the same time if given as this is invalid
  */
  const commonInputProps = {
    type: 'checkbox',
    value: dataValue, // Allow for custom value,
    ...otherProps
  };
  const defaultCheckInputProps = {
    ...commonInputProps,
    defaultChecked
  };
  const nonDefaultCheckInputProps = {
    ...commonInputProps,
    checked: checked ?? Boolean(value)
  };
  const inputProps = removeProps(
    defaultChecked ? defaultCheckInputProps : nonDefaultCheckInputProps,
    ['inputLabel', 'onInput', 'onValueChange', 'thousandSeparator']
  );

  return (
    <div className="lumanti-input_checkbox">
      <input {...inputProps} />
      <span className="lumanti-input_checkbox-container lumanti-input_checkbox-checkmark" />
      <span className="lumanti-input_checkbox-text">{children}</span>
    </div>
  );
};

export { InputCheckbox };
