// Globals
import './styles.scss';
import React, { useState } from 'react';

// Misc
import { capitalize, snakeCase } from 'lodash';
import { checkIfImageExists } from '../../utils/checkIfImageExists';

// Props
interface VendorLogoProps {
  vendorName: string;
}

const VendorLogo: React.FC<VendorLogoProps> = ({ vendorName }) => {
  const [isLogoAvailable, setIsLogoAvailable] = useState(false);
  const formatVendorName = snakeCase(vendorName);

  React.useEffect(() => {
    if (checkIfImageExists(formatVendorName)) {
      setIsLogoAvailable(true);
    } else {
      setIsLogoAvailable(false);
    }
  }, [vendorName]);

  if (!isLogoAvailable) {
    return <>{capitalize(vendorName)}</>;
  }

  return (
    <img
      alt="merchant"
      className="lumanti-vendor_logo"
      src={require(`assets/media/vendor_logos/${formatVendorName}.png`)}
      width="50px"
    ></img>
  );
};

export { VendorLogo };
