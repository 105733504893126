// Globals
import React from 'react';
import './styles.scss';

// Misc
import clsx from 'clsx';
import { iconExceptionsArray } from './data';
import { IconProps } from './types';
import icons from './icons.svg';

// Component
const Icon: React.FC<IconProps> = (props) => {
  // Props
  const {
    badge = null,
    className,
    delay = 1000,
    isDisabled,
    name = '',
    onClick,
    title,
    tooltipText,
    ...domProps
  } = props;

  // Hook - useState
  const [isToolTipVisible, setIsToolTipVisible] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<LmtTimeout>();

  // Hook - useEffect
  React.useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  // Vars
  const classes = clsx(
    {
      'lumanti-icon': true,
      // Per design spec, certain icons will not inherit color styles
      'lumanti-icon-exception': iconExceptionsArray.includes(name),
      'lumanti-icon-disabled': isDisabled
    },
    className
  );

  let iconProps: Pick<IconProps, 'title' | 'onKeyDown' | 'onClick' | 'role' | 'tabIndex'> = {
    title,
    ...domProps
  };

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // disable if disabled for agent
    if (isDisabled) return;

    onClick?.(event);
  };

  if (onClick) {
    iconProps = {
      ...iconProps,
      onClick: handleOnClick,
      role: 'button',
      tabIndex: 0
    };
  }

  // Hover Item handler
  const handleHover = () => {
    // Clear set hidden if possible
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => setIsToolTipVisible(true), delay);
    setTimeoutId(id);
  };

  const handleLeave = () => {
    // Clear set visible if possible
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set .5 seconds to hide
    const id = setTimeout(() => setIsToolTipVisible(false), 500);
    setTimeoutId(id);
  };

  // Render
  return (
    <span
      {...iconProps}
      className={classes}
      onBlur={() => handleLeave()}
      onFocus={() => handleHover()}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => handleLeave()}
    >
      {isToolTipVisible && tooltipText && (
        <span className="lumanti-icon_tooltip">{tooltipText}</span>
      )}
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={`${icons}#${name}`} />
      </svg>
      {badge}
    </span>
  );
};

export { Icon };
