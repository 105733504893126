// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

// Types
import { ModalBodyDialogProps } from './types';

// Component
function ModalBodyDialog(props: ModalBodyDialogProps) {
  // Props
  const { buttonCancel, buttonSave, error, heading, subheading } = props;

  // Render
  return (
    <div className="lumanti-modal_body_dialog">
      <div className="lumanti-modal-content">
        {/* Image */}
        <img
          alt="alert"
          className="lumanti-modal_body_dialog-img"
          src={require('assets/alert.png')}
          width={60}
        />

        {/* Description */}
        <Heading align="center" className="lumanti-modal_body_dialog-heading" size={26} type="h4">
          {heading}
        </Heading>
        <Paragraph align="center" className="lumanti-modal_body_dialog-subheading">
          {subheading}
        </Paragraph>

        {error && <div className="lumanti-modal_body_dialog-toast-error">{error}</div>}

        {/* Buttons */}
        <div className="lumanti-modal_body_dialog-button-container">
          {buttonCancel}
          {buttonSave}
        </div>
      </div>
    </div>
  );
}

export { ModalBodyDialog };
