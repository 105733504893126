export const calculateTax = (amount: number, taxPercent: number): string => {
  return ((amount * taxPercent) / 100).toString();
};

export const calculateDiscount = (amount: string, discountPercent: string): string => {
  return ((Number(amount) * Number(discountPercent)) / 100).toString();
};

export const calculateTotal = (
  subTotal: string,
  discountAmount: string,
  delivery: string,
  taxAmount: string
): string => {
  return (
    Number(subTotal) -
    Number(discountAmount) +
    Number(taxAmount) +
    Number(delivery)
  ).toString();
};

export const convertDollarToCents = (dollar: string): string => {
  return (Number(dollar) * 100).toFixed(0);
};

export const convertCentsToDollar = (cents: string) => {
  return (Number(cents) / 100).toFixed(2);
};

export const formatCurrency = (amount: string) => {
  return Number(amount).toFixed(2);
};
