import './styles.scss';
import React from 'react';

// Component
import { HomeIncomeExpense } from './IncomeExpense';
import { HomeOrdersTransaction } from './OrdersTransactions';
import { HomeWelcome } from './Welcome';
import { Layout } from 'components/Layout';

// Services
import { doGetExpenses } from 'services/expense/async';
import { doGetOrders } from 'services/orders/async';

// Misc
import { LmtDispatch } from 'store';
import { useDispatch } from 'react-redux';

const Home: React.FC = () => {
  // Hooks
  const dispatch: LmtDispatch = useDispatch();

  React.useEffect(() => {
    void dispatch<any>(doGetOrders());
    void dispatch<any>(doGetExpenses());
  }, []);

  return (
    <Layout>
      <div className="lumanti-home">
        <HomeWelcome />
        <HomeIncomeExpense />
        <HomeOrdersTransaction />
        {/*<HomeCardWeeklySales />*/}
        {/*<HomeCardTimeLine />*/}
        {/*<HomeCardWeeklySales />*/}
        {/*<HomeCardTimeLine />*/}
      </div>
    </Layout>
  );
};

export { Home };
