import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { CardExpense } from 'components/CardExpense';
import { FeaturePageHeader } from 'components/PageHeader';
import { Layout } from 'components/Layout';
import { ModalAddExpense } from 'Modals/ModalAddExpense';
import { Table } from 'components/Table';

// Services
import { doGetExpenses } from 'services/expense/async';
import {
  getExpensesListSorted,
  getTotalExpensesThisWeek,
  getTotalExpensesYearToDate
} from 'services/expense/selectors';

// MUI
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

// Misc
import { capitalize } from 'lodash';
import { formatDate } from 'utils/dateFormat';
import { LmtDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '../../utils/currencyFormat';
import { VendorLogo } from '../../components/VendorLogo';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'receiptDate',
    headerName: 'Date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `${formatDate(params.row.receiptDate)}`
  },
  {
    field: 'merchant',
    headerName: 'Merchant',
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <VendorLogo vendorName={params.row.merchant} />
        </>
      );
    }
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `${capitalize(params.row.category)}`
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'string',
    sortable: false,
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => `$ ${params.row.amount}`
  }
];

// Component
const Expenses: React.FC = () => {
  const dispatch: LmtDispatch = useDispatch();

  // Hooks - state
  const [isAddExpenseModalVisible, setIsAddExpensesModalVisible] = React.useState(false);

  // Hooks - selectors
  const expensesList = useSelector(getExpensesListSorted);
  const expensesThisWeek = useSelector(getTotalExpensesThisWeek);
  const expensesYearToDate = useSelector(getTotalExpensesYearToDate);

  // React - useEffect
  React.useEffect(() => {
    dispatch<any>(doGetExpenses());
  }, []);

  return (
    <Layout className="lumanti-expenses">
      <FeaturePageHeader heading="Expenses" />

      <div className="lumanti-expenses-data-cards">
        <CardExpense label="Weekly Expenses" value={formatCurrency(expensesThisWeek)} />
        <CardExpense label="Year to date Expenses" value={formatCurrency(expensesYearToDate)} />
      </div>

      <Button icon="plus" onClick={() => setIsAddExpensesModalVisible(true)}>
        Add Expense
      </Button>

      <Table className="lumanti-expense-table" columns={columns} rows={expensesList} />

      <ModalAddExpense
        hide={() => setIsAddExpensesModalVisible(false)}
        isVisible={isAddExpenseModalVisible}
      />
    </Layout>
  );
};

export { Expenses };
