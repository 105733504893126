import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';

// Misc
import clsx from 'clsx';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  moreOption?: React.ReactNode;
}

// Component
const Card: React.FC<CardProps> = ({ children, className = '', title = '', moreOption = null }) => {
  const classes = clsx('lumanti-card-body', className);
  return (
    <div className="lumanti-card">
      {title && (
        <div className="lumanti-card-heading">
          {
            <Heading size={22} type="h3">
              {title}
            </Heading>
          }
          {moreOption}
        </div>
      )}

      <div className={classes}>{children}</div>
    </div>
  );
};

export { Card };
