import { calculateSumsGroup, calculateTotal } from 'utils/dataTransform';
import { createSelector } from 'reselect';
import { getOrdersList } from 'services/orders/selectors';
import { groupBy } from 'lodash';
import { LmtState } from 'store';
import { getExpensesList } from '../expense/selectors';
import { convertCentsToDollar } from '../../utils/orders';
import { formatCurrency } from '../../utils/currencyFormat';

export const getReports = createSelector(
  (state: LmtState) => state,
  (state) => state.reports
);

export const getReportsDateRangeFilter = createSelector(
  getReports,
  (reports) => reports.dateRangeFilter
);

// Transactions
export const getReportsOrdersByDate = createSelector(
  getOrdersList,
  getReportsDateRangeFilter,
  (orders, range) => {
    return orders.filter((order) => {
      const orderDate = new Date(order.serviceDate);
      const startDate = new Date(range.dateStart);
      const endDate = new Date(range.dateEnd);
      return orderDate >= startDate && orderDate <= endDate;
    });
  }
);

export const getReportsPaidTransactions = createSelector(getReportsOrdersByDate, (orders) => {
  return orders.filter((order) => order?.paymentStatus === 'paid');
});

export const getReportsPaidTransactionsGroupByPaymentMethod = createSelector(
  getReportsPaidTransactions,
  (orders) => groupBy(orders, (order) => order.paymentMethod)
);

export const getReportPaidTransactionsTaxAndDelivery = createSelector(
  getReportsPaidTransactions,
  (orders) => {
    return orders.reduce(
      function (acc, order) {
        acc.taxAmount += parseFloat(order.taxAmount);
        acc.delivery += parseFloat(order.delivery ? order.delivery : '0');
        return acc;
      },
      { taxAmount: 0, delivery: 0 }
    );
  }
);

export const getReportTransactionsSumGroup = createSelector(
  getReportsPaidTransactionsGroupByPaymentMethod,
  (paidList) => calculateSumsGroup(paidList, 'totalAmount')
);

export const getReportTransactionSumGroupTotal = createSelector(
  getReportTransactionsSumGroup,
  (group) => calculateTotal(group)
);

export const getReportTransactions = createSelector(getReportTransactionsSumGroup, (group) => {
  return (
    Object.entries(group).map(function ([type, amount]) {
      return { name: type, value: amount };
    }) || []
  );
});

// Expenses
export const getReportsExpensesByDate = createSelector(
  getExpensesList,
  getReportsDateRangeFilter,
  (expenses, range) => {
    return expenses.filter((expense) => {
      const receiptDate = new Date(expense?.receiptDate);
      const startDate = new Date(range.dateStart);
      const endDate = new Date(range.dateEnd);
      return receiptDate >= startDate && receiptDate <= endDate;
    });
  }
);

export const getReportsExpensesGroupByCategory = createSelector(
  getReportsExpensesByDate,
  (expenses) => {
    return groupBy(expenses, (expense) => expense?.category);
  }
);

export const getReportsExpensesGroupByMerchant = createSelector(
  getReportsExpensesByDate,
  (expenses) => groupBy(expenses, (expense) => expense?.merchant)
);

export const getReportsExpensesGroupByCardOwner = createSelector(
  getReportsExpensesByDate,
  (expenses) => groupBy(expenses, (expense) => expense?.cardOwner)
);

export const getReportsExpensesGroupByCategorySumGroup = createSelector(
  getReportsExpensesGroupByCategory,
  (category) => calculateSumsGroup(category, 'amount')
);

export const getReportsExpensesTotal = createSelector(
  getReportsExpensesGroupByCategorySumGroup,
  (expenses) => calculateTotal(expenses)
);

export const getReportsExpenses = createSelector(
  getReportsExpensesGroupByCategorySumGroup,
  (sumGroup) => {
    return (
      Object.entries(sumGroup).map(function ([type, amount]) {
        return { name: type, value: amount };
      }) || []
    );
  }
);

export const getReportsExpensesGroupByCardOwnerSumGroup = createSelector(
  getReportsExpensesGroupByCardOwner,
  (owner) => calculateSumsGroup(owner, 'amount') || 0
);
