export const stepperClasses = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: '#fafcff' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: '#fafcff' // Just text label (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: '#36f' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-disabled': {
    color: '#cfd5e3' // circle color (COMPLETED)
  },
  '& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text': {
    fill: '#fafcff' // circle's number (ACTIVE)
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: '#fafcff' // Just text label (ACTIVE)
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: '#fafcff' // circle's number (ACTIVE)
  }
};

export const textFieldClasses = {
  marginBottom: '20px',
  '& input': { color: '#d6e6ff' },
  '& .MuiTextField-root': {
    '& fieldset': { borderColor: '#d6e6ff' },
    '&:hover fieldset': { borderColor: '#d6e6ff' },
    '&.Mui-focused fieldset': { borderColor: '#d6e6ff' }
  },
  '& label.Mui-focused': { color: '#d6e6ff' },
  '& .MuiInputLabel-root': { color: '#d6e6ff' },
  '& .MuiInput-underline:after': { borderBottomColor: 'white' },
  '& .MuiToggleButton-root': {
    color: '#d6e6ff',
    borderColor: '#d6e6ff',
    '&.Mui-selected': { color: '#1d2f50', backgroundColor: '#6694ff' }
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#d6e6ff' },
    '&:hover fieldset': { borderColor: '#d6e6ff' },
    '&.Mui-focused fieldset': { borderColor: '#d6e6ff' },
    '& .MuiTypography-root': { color: '#d6e6ff' }
  }
};
